import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import GestGemsysService from '../service/GestGemsysService';
import { Dropdown } from 'primereact/dropdown';
import { SplitButton } from 'primereact/splitbutton';
import { InputTextarea } from 'primereact/inputtextarea';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

/* Page de gestion des familles */
export class FormFamille extends Component {
    constructor() {
        super();
        this.state = {
            selectedData: null,
            selectedMembre: null,
            liste_data: [],
            liste_eleve: [],
            liste_classe: [],
            liste_membre: [],
            formData: {
                id: 0,
                libelleFamille: "",
                contactFamille: "",
                adrFamille: ""
            },
            formDataAppartenir: {
                id: 0,
                libelleFamille: "",
                contactFamille: "",
                adrFamille: ""
            },
            matricule: null,
            totalDu: 0,
            totalPaye: 0,
            totalRestant: 0,
            montant: 0,
            rcode_classe: 'Tous',
            rfiltre: '',
            liste_item_export: [
                {
                    label: 'Exporter en CVS',
                    icon: 'pi pi-download',
                    command: (e) => {
                        this.exportCSV();
                    }
                },
                {
                    label: 'Exporter en XLS',
                    icon: 'pi pi-file-excel',
                    command: (e) => {
                        this.exportExcel();
                    }
                },
                {
                    label: 'Exporter en PDF',
                    icon: 'pi pi-file-pdf',
                    command: (e) => {
                        this.exportPdf();
                    }
                },
            ],
            showindicator: false,
            visible_dlg_membre: false,
            modif_data: false,
            submit: false,
            visible_dlg_delete: false,
            visible_dlg_form: false,
            visible_dlg_form_com: false,
            visible_dlg_detail: false,
        };
        this.edit_data = this.edit_data.bind(this);
        this.conf_delete_data = this.conf_delete_data.bind(this);
        this.toogleDlg = this.toogleDlg.bind(this);
        this.show_membre_dlg = this.show_membre_dlg.bind(this);
        this.conf_delete_membre=this.conf_delete_membre.bind(this);
        this.appservice = new GestGemsysService();
    }


    /* Natif react js (s'exécute au montage ou chargement de la page )*/
    componentDidMount() {
        this.get_liste_data();
        this.get_liste_classe();
    }

    /* Récupération de la liste globale des membres */
    get_liste_membre() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_membres_famille(this.state.selectedData.id).then(data => {
            this.setState({ liste_membre: data, showindicator: false });
        });
    }

    /* Récupération de la liste globale des familles */
    get_liste_data() {
        this.setState({ showindicator: true });
        this.appservice.get_liste_famille().then(data => {
            this.setState({ liste_data: data, showindicator: false });
        });
    }

    get_liste_classe() {
        this.setState({ showindicator: true, liste_classe: [] });
        this.appservice.getClasse().then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined) {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        let lc = this.appservice.get_userconnected().idEtabl.typeEtabl == 'Université' ? 'S' : '';
                        return { value: x.codeClasse, label: lc + x.libClasse + " " + x.optClasse, codeEnseign: x.codeEnseign.codeEnseign }
                    })
                    this.setState({ liste_classe: liste });
                }
            }
        });
    }


    /* Annuler l'opération en cours et vider le formulaire */
    annuler() {
        this.setState({
            formData: {
                id: 0,
                libelleFamille: "",
                contactFamille: "",
                adrFamille: ""
            }
        });

        this.setState({ showindicator: false });
        this.setState({ modif_data: false });
        this.setState({ visible_dlg_delete: false });
        this.setState({ visible_dlg_form: false });
        this.setState({ visible_dlg_form_com: false });
    }

    /* Remplissage du formulaire avec les informations de la ligne sélectionnée dans le cadre de la modification  */
    edit_data(data) {
        if (data !== null && data !== undefined) {
            this.setState({ formData: data });
            this.setState({ modif_data: true });
            this.setState({ visible_dlg_form: true });
            this.setState({ selectedData: data });
        }
    }

    /* Enregistrer les informations du formulaire famille */
    enreg_data() {
        this.setState({ submit: true });
        if (this.state.formData.libelleFamille == "" || this.state.formData.adrFamille == "" || this.state.formData.contactFamille == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
        } else {
            this.setState({ showindicator: true });
            let form = this.state.formData;
            this.appservice.save_famille(form).then(reponse => {
                if (reponse != undefined && reponse != null) {
                    this.resultat(reponse);
                    if (reponse.code == 200) {
                        this.get_liste_data();
                    }
                }
            });
        }
    }
 
    enreg_membre() {
        this.setState({ submit: true });
        if (this.state.matricule == "" || this.state.matricule == null) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner le membre' });
        } else {
            this.setState({ showindicator: true });
            let form = {
                id: 0,
                familleId: this.state.selectedData,
                matricule: { matricule: this.state.matricule }
            };
            this.appservice.save_appartenir(form).then(reponse => {
                if (reponse != undefined && reponse != null) {
                    this.resultat(reponse);
                    if (reponse.code == 200) {
                        this.get_liste_membre();
                        this.get_liste_data();
                    }
                }
            }); 
        }
    }

    get_liste_eleve() {
        if (this.state.rfiltre == "" && this.state.rcode_classe == null) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner une classe ou filtrer par matricule ou par nom' });
            return;
        }
        this.setState({ showindicator: true, liste_eleve: [] });
        this.appservice.get_liste_eleve_membre(this.state.rcode_classe, this.state.rfiltre == "" ? "Tous" : this.state.rfiltre).then(data => {
            this.setState({ showindicator: false });
            document.getElementById("cbEleve").click();
            if (data !== undefined && data !== null) {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.matricule, label: x.nomEleve + ' ' + x.prenomEleve }
                    });
                    this.setState({ liste_eleve: liste });
                }
            }
        });
    }

    /* Affichage de la boite de dialogue de confirmation de suppression */
    conf_delete_data(data) {
        this.setState({ visible_dlg_delete: true });
        this.setState({ selectedData: data });
    }

    conf_delete_membre(data) {
        this.setState({ visible_dlg_delete_membre: true });
        this.setState({ selectedMembre: data });
    }

    show_membre_dlg(data) {
        this.setState({ visible_dlg_membre: true });
        this.setState({ selectedData: data }, () => {
            this.get_liste_membre();
        });
    }

    /* Affichage de la boite de dialogue pour l'ajout d'une nouvelle adresse */
    toogleDlg() {
        this.annuler();
        this.setState({ visible_dlg_form: !this.state.visible_dlg_form });
    }

    /* Zone gauche de la toolbar */
    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Ajouter" icon="pi pi-plus" className="p-button-success mr-2" onClick={this.toogleDlg} style={{ width: 115 }} />
                    <Button label="Supprimer" disabled={this.state.selectedData == null} icon="pi pi-trash" className="p-button-danger" onClick={(e) => this.conf_delete_data(this.state.selectedData)} style={{ width: 115 }} />
                </div>
            </React.Fragment>
        )

    }

    /* Zone droite de la toolbar */
    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> :
                    <SplitButton label="Exporter" icon="pi pi-download" className="p-button-help" onClick={this.exportExcel} model={this.state.liste_item_export} />
                }
            </React.Fragment>
        )
    }

    /* Exportation du tableau contenant la liste des quartiers en fichier CSV */
    exportCSV = () => {
        this.dt.exportCSV("Liste_type_recette");
    }


    /* Fonction d'affichage des messages retournés suite aux actions serveurs */
    resultat(rep) {
        if (rep.code == 200) {
            this.toast.show({ severity: 'success', summary: 'Success', detail: rep.contenu });
            this.annuler();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: rep.contenu });
        }
        this.setState({ submit: false });
    }

    /* Colonne Action du tableau contenant la liste des quartiers */
    actionTemplate(rowData, column) {
        const items = [
            {
                label: 'Modifier',
                icon: 'pi pi-pencil',
                command: (e) => {
                    this.edit_data(rowData);
                }
            },
            {
                label: 'Supprimer',
                icon: 'pi pi-times',
                command: (e) => {
                    this.conf_delete_data(rowData);
                }
            },
            {
                label: 'Membres',
                icon: 'pi pi-sitemap',
                command: (e) => {
                    this.show_membre_dlg(rowData);
                }
            },
        ]
        return <SplitButton label="Membres" icon="pi pi-sitemap" onClick={(e) => this.show_membre_dlg(rowData)} model={items}></SplitButton>
    }

    actionTemplateMembre(rowData) {
        return<Button onClick={() => this.conf_delete_membre(rowData)} className="p-button-danger" style={{ width: 25, height: 25}} icon="pi pi-times" />
    }

    /* Fonction de suppression du quartier sélectionner */
    delete_data() {
        if (this.state.selectedData == null || this.state.selectedData == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.appservice.delete_famille(this.state.selectedData).then(reponse => {
                this.resultat(reponse);
                if (reponse.code == 200) {
                    this.get_liste_data();
                }
            });
        }
    }

    /* Exportation du tableau  en fichier PDF */
    exportPdf = () => {
        const cols = [
            { field: 'FAMILLE', header: 'FAMILLE' },
            { field: 'ADRESSE', header: 'ADRESSE' },
            { field: 'CONTACT', header: 'CONTACT' }
        ];
        let liste_data = this.state.liste_data.length > 0 ? this.state.liste_data.map(x => {
            return { 'FAMILLE': x.libelleFamille, 'CONTACT': x.contactFamille, 'ADRESSE': x.adrFamille }
        }) : [];
        const exportColumns = cols.map(col => ({ title: col.header, dataKey: col.field }));
        import('jspdf').then(jsPDF => {
            import('jspdf-autotable').then(() => {
                var doc = new jsPDF.default(0, 0);
                doc.autoTable(exportColumns, liste_data);
                doc.save('FAMILLES.pdf');
            })
        })
    }

    /* Exportation du tableau  en fichier XLSX */
    exportExcel = () => {
        let liste_data = this.state.liste_data.length > 0 ? this.state.liste_data.map(x => {
            return { 'FAMILLE': x.libelleFamille, 'CONTACT': x.contactFamille, 'ADRESSE': x.adrFamille }
        }) : [];
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(liste_data);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, 'FAMILLES');
        });
    }

    /* Enregistrer le fichier sur le client */
    saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }
 
    photoTemplate(rowData, column) {
        let fic = rowData.matricule.photo;
        if (fic === undefined || fic === null || fic === '') {
            fic = 'nophoto.png';
        }
        return <img src={this.appservice.download_file(fic, 'photos/')} alt='Photo' style={{ width: 40, height: 40 }} />
    }

    handleKeyPress = e => {
        if (e.keyCode === 13) {
            this.get_liste_eleve();
        }
    }

    /* Bloc visuel (propre à React) */
    render() {

        let headerGroup = <ColumnGroup>
            <Row>
                <Column header="#" rowSpan={2} />
                <Column header="Photo" rowSpan={2} />
                <Column header="Prénoms et nom" rowSpan={2} />
                <Column header="Classe" rowSpan={2} />
                <Column header="Scolarité" colSpan={2} />
                <Column header="Inscription" colSpan={2} />
                <Column header="Badge" colSpan={2} />
                <Column header="Restant" rowSpan={2} />
            </Row>
            <Row>
                <Column header='Payé' field="fspaye" />
                <Column header='Restant' field="fsrest" />

                <Column header='Payé' field="fipaye" />
                <Column header='Restant' field="firest" />

                <Column header='Payé' field="fbpaye" />
                <Column header='Restant' field="fbrest" />
            </Row>
        </ColumnGroup>;

        return (
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card" style={{ height: 770 }}>

                        {/* Toast pour l'afficahge des messages de dialogue */}
                        <Toast ref={(el) => this.toast = el} />

                        {/* Toolbar contenant les boutons d'ajout, de suppression et d'exportation */}
                        <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                        <br />

                        {/* Tableau contenant la liste des quartiers existants dans la base */}
                        <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_data} resizableColumns="scroll"
                            rows={10} paginator={true} className="p-datatable-customers" showGridlines
                            selection={this.state.selectedData} onRowSelect={e => this.setState({ selectedData: e.data })}
                            emptyMessage="No data found" selectionMode="single" alwaysShowPaginator={false}
                            onRowClick={e => this.setState({ selectedData: e.data }, () => {
                            })}>
                            {/* <Column field="id" header="ID" filter={true} filterMatchMode='contains' /> */}
                            <Column field="Index" header="No" body={(rowData, props) => {
                                return <div>
                                    <span >{props.rowIndex + 1}</span>
                                </div>
                            }} style={{ width: '3%' }} />
                            <Column body={this.actionTemplate.bind(this)} header="Action" style={{ width: '10%' }} />
                            <Column field="libelleFamille" header="Famille" filter={true} filterMatchMode='contains' />
                            <Column field="adrFamille" header="Adresse" filter={true} filterMatchMode='contains' />
                            <Column field="contactFamille" header="Contact" style={{ width: '12%' }} filter={true} filterMatchMode='contains' />
                            <Column field="totalDu" body={(rowData, column) => {
                                return <div>
                                    <span style={{ color: '#000', fontWeight: 'bold' }}>{this.appservice.formaterValueSep(parseFloat(rowData.totalDu))}</span>
                                </div>
                            }} header="Total" style={{ width: '12%' }} sortable={true} />
                            <Column field="totalPaye" body={(rowData, column) => {
                                return <div>
                                    <span style={{ color: '#000', fontWeight: 'bold' }}>{this.appservice.formaterValueSep(parseFloat(rowData.totalPaye))}</span>
                                </div>
                            }} header="Payé" style={{ width: '12%' }} sortable={true} />
                            <Column field="totalRestant" body={(rowData, column) => {
                                return <div>
                                    <span style={{ color: 'red', fontWeight: 'bold' }}>{this.appservice.formaterValueSep(parseFloat(rowData.totalRestant))}</span>
                                </div>
                            }} header="Restant" style={{ width: '12%' }} sortable={true} />
                        </DataTable>


                        {/* Boite de dialogue d'affichage des détails pour impression en bloc */}
                        <Dialog visible={this.state.visible_dlg_membre} style={{ width: '90%' }} header="Gestion des membres" modal onHide={(e) => this.setState({ visible_dlg_membre: false })} appendTo={document.body}>
                            <div className="card card-w-title p-fluid" style={{ backgroundColor: '#fbfcfc', paddingBlock: 5 }}>
                                <i className="pi pi-user"></i>
                                <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Ajout d'un membre</span>
                                <div className="grid">
                                    <div className="col-12 md:col-3">
                                        <Dropdown options={this.state.liste_classe} placeholder="Classe" value={this.state.rcode_classe} onChange={(e) => this.setState({ rcode_classe: e.value }, () => {
                                            this.get_liste_eleve();
                                        })} autoWidth={false} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' panelStyle={{ width: '400px' }} />
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <div className="p-inputgroup">
                                            <InputText value={this.state.rfiltre} placeholder="Matricule ou nom" onKeyDown={this.handleKeyPress} onChange={(e) => this.setState({ rfiltre: e.target.value })} style={{ width: '90%' }} />
                                            <Button icon='pi pi-search' style={{ width: 50 }} onClick={() => {
                                                this.get_liste_eleve();
                                            }} loading={this.state.showindicator} />
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-4">
                                        <Dropdown id="cbEleve" options={this.state.liste_eleve} placeholder="Membre" value={this.state.matricule} onChange={(e) => this.setState({ matricule: e.value })} autoWidth={false} optionLabel='label' filter={true} filterBy='label,value' filterMatchMode='contains' itemTemplate={(option) => {
                                            if (!option.value) {
                                                return option.label;
                                            } else {
                                                return (
                                                    <div className="grid" >
                                                        <div className="col-12 md:col-3">
                                                            <label>{option.value}</label>
                                                        </div>
                                                        <div className="col-12 md:col-9">
                                                            <label>{option.label}</label>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        }} panelStyle={{ width: '500px' }} />
                                    </div>
                                    <div className="col-12 md:col-2">
                                        <Button label="Ajouter" loading={this.state.showindicator} icon="pi pi-plus" onClick={(e) => this.enreg_membre()} style={{ width: 100 }} className="p-button-success mr-2" />
                                    </div>

                                </div>
                            </div>
                            {/* Tableau contenant la liste des membres */}
                            <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_membre} resizableColumns="scroll"
                                paginator rows={8} showGridlines className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                selection={this.state.selectedMembre} onSelectionChange={e => this.setState({ selectedMembre: e.value })}
                                emptyMessage="No data found" alwaysShowPaginator={false} selectionMode="single"
                                headerColumnGroup={headerGroup} >
                                <Column body={this.actionTemplateMembre.bind(this)} header="#" style={{ width: '3%' }} />
                                <Column body={this.photoTemplate.bind(this)} header="Photo" style={{ width: '5%' }} exportable={false} />
                                <Column field="matricule.prenomEleve" body={(rowData, column) => {
                                    return <div>
                                        <span>{rowData.matricule.prenomEleve} {rowData.matricule.nomEleve}</span>
                                    </div>
                                }} header="Prénoms et Nom" filter={true} filterMatchMode='contains' style={{ width: '12%' }} />
                                <Column field="classe" header="Classe" filter={true} filterMatchMode='contains' />

                                <Column field="fspaye" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000', fontWeight: 'bold' }}>{this.appservice.formaterValueSep(parseFloat(rowData.fspaye))}</span>
                                    </div>
                                }} header="Scol Payé" style={{ width: '12%' }} sortable={true} />
                                <Column field="fsrest" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: 'red', fontWeight: 'bold' }}>{this.appservice.formaterValueSep(parseFloat(rowData.fsrest))}</span>
                                    </div>
                                }} header="Scol. restant" style={{ width: '12%' }} sortable={true} />

                                <Column field="fipaye" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000', fontWeight: 'bold' }}>{this.appservice.formaterValueSep(parseFloat(rowData.fipaye))}</span>
                                    </div>
                                }} header="Insc Payé" style={{ width: '12%' }} sortable={true} />
                                <Column field="firest" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: 'red', fontWeight: 'bold' }}>{this.appservice.formaterValueSep(parseFloat(rowData.firest))}</span>
                                    </div>
                                }} header="Insc. restant" style={{ width: '12%' }} sortable={true} />

                                <Column field="fbpaye" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: '#000', fontWeight: 'bold' }}>{this.appservice.formaterValueSep(parseFloat(rowData.fbpaye))}</span>
                                    </div>
                                }} header="Badge Payé" style={{ width: '12%' }} sortable={true} />
                                <Column field="fbrest" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: 'red', fontWeight: 'bold' }}>{this.appservice.formaterValueSep(parseFloat(rowData.fbrest))}</span>
                                    </div>
                                }} header="Badge restant" style={{ width: '12%' }} sortable={true} />

                                <Column field="ttrest" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: 'red', fontWeight: 'bold' }}>{this.appservice.formaterValueSep(parseFloat(rowData.ttrest))}</span>
                                    </div>
                                }} header="Restant" style={{ width: '10%' }} sortable={true} />

                            </DataTable>
                        </Dialog>






                        {/* Boite de dialogue d'ajout et de modification d'une famille  */}
                        <Dialog visible={this.state.visible_dlg_form} style={{ width: '450px' }} header={this.state.modif_data == true ? "Modification" : "Ajout"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form: false })}
                            footer={<>
                                <Button label="Fermer" icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.annuler()} />
                                <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_data()} style={{ width: 120 }} />
                            </>}>
                            <div className="p-fluid">

                                <div className="field">
                                    <label htmlFor="name1">Famille *</label>
                                    <InputText id="name1" value={this.state.formData.libelleFamille} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.libelleFamille })}
                                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'libelleFamille', '', this.state.formData) })}
                                        required autoFocus />
                                    {this.state.submit && !this.state.formData.libelleFamille && <small className="p-invalid" style={{ color: 'red' }}>Le libelle est obligatoire.</small>}
                                </div>
                                <div className="field">
                                    <label htmlFor="name1">Contact *</label>
                                    <InputText id="name1" value={this.state.formData.contactFamille} onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'contactFamille', '', this.state.formData) })} />
                                </div>
                                <div className="field">
                                    <label htmlFor="email1">Adresse *  </label>
                                    <InputTextarea id="name" value={this.state.formData.adrFamille} rows={2} onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'adrFamille', '', this.state.formData) })} />
                                </div>
                            </div>
                        </Dialog>

                        {/* Boite de dialogue de confirmation de suppression */}
                        <Dialog visible={this.state.visible_dlg_delete} style={{ width: '450px' }} header="Confirmation" modal
                            footer={
                                <>
                                    <Button label="Non" icon="pi pi-times" className="p-button-secondary" onClick={() => {
                                        this.setState({ visible_dlg_delete: false });
                                    }} />
                                    <Button label="Oui" icon="pi pi-check" className="p-button-danger" onClick={() => {
                                        this.setState({ visible_dlg_delete: false });
                                        this.delete_data();
                                    }} />
                                </>
                            } onHide={(e) => this.setState({ visible_dlg_delete: false })}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                {this.state.selectedData && <span><label style={{ color: 'red', fontWeight: 'bold' }}></label>voulez-vous vraiment supprimer <b>{this.state.selectedData.libelleFamille}</b>?</span>}
                            </div>
                        </Dialog>

                    </div>
                </div>
            </div >
        );
    }
}
