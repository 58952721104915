import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import GestGemsysService from '../service/GestGemsysService';
import './styles.css';
import { Panel } from 'primereact/panel';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { SplitButton } from 'primereact/splitbutton';
import { classNames } from 'primereact/utils';

export class FormValidationInscription extends Component {
    constructor() {
        super();
        this.state = {
            rclasse: 'Tous',
            liste_souscription: [],
            selectedSouscription: null,
            liste_inscriptions: [],
            liste_service: [],
            liste_classes: [],
            selectedInscriptions: [],
            formDataSouscription: {
                id: 0,
                dateSouscription: null,
                etatSouscription: "Actif",
                dateDesactivation: null,
                idInsc: null,
                idService: { id: 0 },
                montantSouscription: 0,
            },
            selectedData: null,
            msg: '',
            modif: false,
            visible: false,
            mtTranche1: 0,
            mtTranche2: 0,
            mtTranche3: 0,
            fraisInscription: 0,
            fraisReinscription: 0,
            fraisBadge: 0,
            showindicator: false,
            visible_dlg_souscription: false,
            visible_dlg_paiement: false,
            remiseInsc: 0,
            margeInsc: 0,

            totalPrest: 0,
            totalInsc: 0,
            totalScol: 0,
            totalBadge: 0,
            total: 0,
        };

        this.fraisScolariteEditor = this.fraisScolariteEditor.bind(this);
        this.fraisBadgeEditor = this.fraisBadgeEditor.bind(this);
        this.fraisInscReinscEditor = this.fraisInscReinscEditor.bind(this);
        this.montantSouscEditor = this.montantSouscEditor.bind(this);
        this.numQuitanceEditor = this.numQuitanceEditor.bind(this);
        this.inputNumberEditor = this.inputNumberEditor.bind(this);
        this.gestGemsysService = new GestGemsysService();
    }


    montantSouscEditor(options) {
        return <InputText value={options.value} keyfilter="pnum" onChange={(e) => options.editorCallback(e.target.value)} />
    }

    onEditorValueChange(props, value) {
        let updateds = [props.value];
        updateds[props.rowIndex][props.field] = value;
        this.setState({ liste_inscriptions: updateds });
    }

    inputNumberEditor(props, field, vmax) {
        /*  return <InputNumber disabled={props.rowData[vmax]==0} min={0} max={props.rowData[vmax]} value={props.rowData[field]} onChange={(e) => {
             if (e.value > props.rowData[vmax]) {
                 this.onEditorValueChange(props, props.rowData[vmax])
             } else {
                 this.onEditorValueChange(props, e.value)
             }
         }} />; */
        return <InputNumber min={0} value={props.rowData[field]} onChange={(e) => {
            this.onEditorValueChange(props, e.value)
        }} />;
    }

    fraisScolariteEditor(options) {
        //return <InputNumber value={options.value} min={0}  onValueChange={(e) => { options.editorCallback(e.value) }} />
        return <InputText value={options.value} keyfilter="pnum" onChange={(e) => options.editorCallback(e.target.value)} />

    }

    onCellEditComplete = (e) => {
        let { rowData, newValue, field, originalEvent: event } = e;
        let val = newValue + '';
        if (val.length > 0) {
            rowData[field] = newValue;
        } else {
            event.preventDefault();
        }
    }

    fraisBadgeEditor(options) {
        // return <InputNumber value={options.value} min={0}  onValueChange={(e) => options.editorCallback(e.value)} />
        return <InputText value={options.value} keyfilter="pnum" onChange={(e) => options.editorCallback(e.target.value)} />

    }

    fraisInscReinscEditor(options) {
        //return this.inputNumberEditor(props, 'fraisInscReinsc', 'maxfraisInscReinsc');
        // return <InputNumber value={options.value} min={0}  onValueChange={(e) => options.editorCallback(e.value)} />
        return <InputText value={options.value} keyfilter="pnum" onChange={(e) => options.editorCallback(e.target.value)} />
    }

    numQuitanceEditor(options) {
        return <InputText value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />
    }

    componentDidMount() {
        this.get_liste_prestation();
        this.gestGemsysService.getClasse().then(data => this.setState({ liste_classes: data }));
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.get_liste_inscrit_for_validation();
            this.setState({ selectedInscriptions: [] });
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    get_liste_inscrit_for_validation() {
        this.setState({
            fraisBadge: 0,
            fraisInscription: 0,
            fraisReinscription: 0,
            mtTranche1: 0,
            mtTranche2: 0,
            mtTranche3: 0,
        });
        this.setState({ showindicator: true });
        this.gestGemsysService.getListe_inscrit_for_validation(this.state.rclasse).then(data => this.setState({ liste_inscriptions: data }, () => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '' && data.length > 0) {
                this.setState({
                    fraisBadge: data[0].codeClasse.fraisBadge,
                    fraisInscription: data[0].codeClasse.fraisInscription,
                    fraisReinscription: data[0].codeClasse.fraisReinscription,
                    mtTranche1: data[0].codeClasse.mtTranche1,
                    mtTranche2: data[0].codeClasse.mtTranche2,
                    mtTranche3: data[0].codeClasse.mtTranche3,
                })
            }
        }));
    }

    valider_entier() {
        this.setState({ showindicator: true });
        let form=this.state.selectedData;
        form.remiseInsc=this.state.remiseInsc;
        form.margeInsc=this.state.margeInsc;
        this.gestGemsysService.ValiderInscritEntier(form).then(data => {
            this.setState({ showindicator: false });
            if (data != null && data != undefined && data.code == '200') {
                this.toast.show({ severity: 'success', summary: 'Success', detail: 'Validation et paiement réussis' });
                this.setState({ showindicator: true, visible_dlg_paiement: false });
                this.gestGemsysService.generer_liste_recu(data.id).then(reponse => {
                    this.setState({ showindicator: false });
                });
                this.get_liste_inscrit_for_validation();
            } else {
                this.resultat(data.code, data.contenu);
            }
        });

    }

    valider_inscriptions() {
        if (this.state.selectedInscriptions == null || this.state.selectedInscriptions == undefined || this.state.selectedInscriptions.length <= 0) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les lignes' });
        } else {
            this.setState({ showindicator: true });
            let error = false;
            let genererRecu = false;
            for (var i = this.state.selectedInscriptions.length; i--;) {
                if (this.state.selectedInscriptions[i].fraisScolarite > 0 || this.state.selectedInscriptions[i].fraisInscReinsc > 0) {
                    genererRecu = true;
                }
                if ((this.state.selectedInscriptions[i].fraisScolarite > this.state.selectedInscriptions[i].maxfraisScolarite) || (this.state.selectedInscriptions[i].fraisInscReinsc > this.state.selectedInscriptions[i].maxfraisInscReinsc) || (this.state.selectedInscriptions[i].fraisBadge > this.state.selectedInscriptions[i].maxfraisBadge)) {
                    error = true;
                    break;
                }
            }
            if (error == true) {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez vérifier les montants saisis' });
                return;
            }
            this.gestGemsysService.ValiderInscrit(this.state.selectedInscriptions).then(data => {
                if (data != null && data != undefined && data.code == '200') {
                    let ids = "";
                    if (this.state.selectedInscriptions.length > 0) {
                        ids = this.state.selectedInscriptions.map(x => {
                            return x.idInsc
                        }).join("_");
                    } else {
                        ids = this.state.liste_inscriptions.map(x => {
                            return x.idInsc
                        }).join("_");
                    }
                    this.toast.show({ severity: 'success', summary: 'Success', detail: 'Validation réussie' });
                    if (this.gestGemsysService.get_categorie_etabl() == 'Privé') {
                        if (genererRecu == true) {
                            this.setState({ showindicator: true });
                            this.gestGemsysService.generer_liste_recu(data.id).then(reponse => {
                                this.setState({ showindicator: false });
                            });
                        }
                    } else {
                        this.gestGemsysService.generer_fiche_insc(ids);
                        //this.gestGemsysService.generer_fiche_paie(ids);
                    }
                    this.get_liste_inscrit_for_validation();
                    this.setState({ selectedInscriptions: [] });
                } else {
                    this.resultat(data.code, data.contenu);
                }
            });
        }
    }

    photoTemplate(rowData, column) {
        let fic = rowData.matricule.photo;
        if (fic === undefined || fic === null || fic === '') {
            fic = 'nophoto.png';
        }
        return <img src={this.gestGemsysService.download_file(fic, 'photos/')} alt='Photo' style={{ width: 45, height: 45 }} />
    }

    delete_inscriptions() {
        if (this.state.selectedInscriptions == null || this.state.selectedInscriptions == undefined || this.state.selectedInscriptions.length <= 0) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les lignes à supprimer' });
        } else {
            this.gestGemsysService.DeleteInscritBloc(this.state.selectedInscriptions).then(data => this.resultat(data.code, data.contenu));
        }
    }

    conf_delete_inscription() {
        if (this.state.selectedInscriptions == null || this.state.selectedInscriptions == undefined || this.state.selectedInscriptions.length <= 0) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les lignes à supprimer' });
        } else {
            this.setState({ visible: true });
        }
    }

    /* Récupération de la liste globale des prestations annexes */
    get_liste_prestation() {
        this.setState({ showindicator: true, liste_service: [] });
        this.gestGemsysService.get_liste_codif_prestation('TYPE_RECETTE', 'PRESTATIONS ANNEXES').then(data => {
            this.setState({ showindicator: false });
            if (data !== undefined && data !== null) {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.id, label: x.libelleCodif, tarif: x.valCodif }
                    });
                    this.setState({ liste_service: liste });
                }
            }
        });
    }

    resultat2(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }

    /* Récupération de la liste des souscriptions par élève */
    get_liste_souscription() {
        this.setState({ showindicator: true });
        this.gestGemsysService.get_liste_souscription_by_inscrit(this.state.selectedData.idInsc).then(data => {
            this.setState({ liste_souscription: data, showindicator: false });
            if (data !== null && data !== undefined) {
                if (data.length > 0) {
                    let liste = data.filter(function (el) { return el.etatSouscription === 'Actif' });
                    let initialValue = 0;
                    let totalPrest = liste.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.montantSouscription
                    }, initialValue);
                    this.setState({ totalPrest: totalPrest });
                }
            }
        });
    }

    show_prestation(rowData, props) {
        this.get_liste_souscription();
        this.setState({ visible_dlg_souscription: true });
    }

    calcul_montant() {
        this.setState({
            totalBadge: this.state.fraisBadge,
            totalInsc: this.state.selectedData.typeInsc == 'Inscription' ? this.state.fraisInscription : this.state.fraisReinscription,
            totalScol: this.state.mtTranche1 + this.state.mtTranche2 + this.state.mtTranche3 + this.state.margeInsc - this.state.remiseInsc,
        });
    }

    show_paiement(rowData, props) {
        this.get_liste_souscription();
        this.setState({
            visible_dlg_paiement: true,
        });
        this.calcul_montant();
    }


    enreg_data_souscription() {
        this.setState({ submit: true });
        if (this.state.formDataSouscription.idService.id == 0 || this.state.formDataSouscription.montantSouscription == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
        } else {
            let form = this.state.formDataSouscription;
            this.setState({ showindicator: true });
            form.idInsc = { idInsc: this.state.selectedData.idInsc };
            this.gestGemsysService.save_souscription(form).then(reponse => {
                this.setState({ showindicator: false });
                if (reponse != undefined && reponse != null) {
                    this.resultat2(reponse.code, reponse.contenu);
                    if (reponse.code == 200) {
                        this.get_liste_souscription();
                    }
                }
            });
        }
    }

    toogle_souscription(form, etat) {
        form.etatSouscription = etat;
        this.setState({ showindicator: true });
        this.gemsysService.save_souscription(form).then(reponse => {
            this.setState({ showindicator: false });
            if (reponse != undefined && reponse != null) {
                this.resultat(reponse.code, reponse.contenu);
                if (reponse.code == 200) {
                    this.get_liste_souscription();
                }
            }
        });
    }

    actionTemplateSouscription(rowData) {
        if (rowData.etatSouscription == 'Actif') {
            return <Button onClick={() => this.toogle_souscription(rowData, 'Résilié')} className="p-button-success" style={{ width: 25, height: 25 }} icon="pi pi-thumbs-down" />
        } else {
            return <Button onClick={() => this.toogle_souscription(rowData, 'Actif')} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-thumbs-up" />
        }
    }

    /* Colonne Action du tableau contenant la liste des quartiers */
    actionTemplate(rowData, column) {
        const items = [
            {
                label: 'Prestations',
                icon: 'pi pi-star-fill',
                command: (e) => {
                    this.setState({ selectedData: rowData }, () => {
                        this.show_prestation(rowData);
                    });
                }
            },
            {
                label: 'Paiement entier',
                icon: 'pi pi-wallet',
                command: (e) => {
                    this.setState({ selectedData: rowData }, () => {
                        this.show_paiement(rowData);
                    });
                }
            },

        ]
        return <SplitButton icon="pi pi-star-fill" onClick={(e) => {
            this.setState({ selectedData: rowData }, () => {
                this.show_prestation(rowData);
            });
        }} model={items}></SplitButton>
    }


    render() {
        let rclasses = null;
        if (this.state.liste_classes != null && this.state.liste_classes != undefined) {
            rclasses = this.state.liste_classes.map(x => {
                let lc = this.gestGemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ? 'S' : '';
                return { value: x.codeClasse, label: lc + x.libClasse + " " + x.optClasse }
            });
        }
        const dialogFooter = (
            <div>
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_inscriptions();
                }} label="Oui" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer les lignes sélectionnées ?
                    </div>
                </Dialog>
                <Dialog header="Paiement entier" visible={this.state.visible_dlg_paiement} style={{ width: '450px' }}
                    modal={true} onHide={() => this.setState({ visible_dlg_paiement: false })}
                    footer={<>
                        <Button label="Fermer" icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.setState({ visible_dlg_paiement: false })} />
                        <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.valider_entier()} style={{ width: 120 }} />
                    </>}>
                    <div className="p-fluid grid">
                        <div className="col-12 md:col-2">
                            <label >Remise</label>
                        </div>
                        <div className="col-12 md:col-4">
                            <InputNumber value={this.state.remiseInsc} onChange={(e) => this.setState({ remiseInsc: e.value },()=>{
                                this.calcul_montant();
                            })} />
                        </div>
                        <div className="col-12 md:col-2">
                            <label >Marge</label>
                        </div>
                        <div className="col-12 md:col-4">
                            <InputNumber value={this.state.margeInsc} onChange={(e) => this.setState({ margeInsc: e.value },()=>{
                                 this.calcul_montant();
                            })} />
                        </div>
                    </div>
                    <hr style={{ padding: 0 }} />
                    <div className="p-fluid formgrid grid" style={{ backgroundColor: '#ebf5fb', padding: 10, borderRadius: 10 }}>
                        <div className="col-12 md:col-3">
                            <label style={{ fontSize: 18 }}>Inscription</label>
                        </div>
                        <div className="col-12 md:col-9">
                            <label style={{ fontWeight: 'bold', fontSize: 18 }} >{this.gestGemsysService.formaterValueSep(this.state.totalInsc)}</label>
                        </div>
                        <div className="col-12 md:col-3">
                            <label style={{ fontSize: 18 }}>Badge</label>
                        </div>
                        <div className="col-12 md:col-9">
                            <label style={{ fontWeight: 'bold', fontSize: 18 }} >{this.gestGemsysService.formaterValueSep(this.state.totalBadge)}</label>
                        </div>
                        <div className="col-12 md:col-3">
                            <label style={{ fontSize: 18 }}>Scolarité</label>
                        </div>
                        <div className="col-12 md:col-9">
                            <label style={{ fontWeight: 'bold', fontSize: 18 }} >{this.gestGemsysService.formaterValueSep(this.state.totalScol)}</label>
                        </div>
                        <div className="col-12 md:col-3">
                            <label style={{ fontSize: 18 }}>Prestations</label>
                        </div>
                        <div className="col-12 md:col-9">
                            <label style={{ fontWeight: 'bold', fontSize: 18 }} >{this.gestGemsysService.formaterValueSep(this.state.totalPrest)}</label>
                        </div>
                    </div>
                    <hr style={{ padding: 0 }} />
                    <div className="p-fluid formgrid grid card" style={{ backgroundColor: '#e1f8a3', paddingBlock: 5 }}>
                        <div className="col-12 md:col-3">
                            <label style={{ fontSize: 18 }}>TOTAL</label>
                        </div>
                        <div className="col-12 md:col-9">
                            <label style={{ fontWeight: 'bold', fontSize: 18, color: 'red' }} >{this.gestGemsysService.formaterValueSep(this.state.totalBadge + this.state.totalInsc + this.state.totalScol + this.state.totalPrest)}</label>
                        </div>
                    </div>
                </Dialog>

                <Dialog header={"Souscription [ " + (this.state.selectedData !== null ? this.state.selectedData.matricule.prenomEleve + ' ' + this.state.selectedData.matricule.nomEleve : '') + " ]"} visible={this.state.visible_dlg_souscription} style={{ width: '70%' }} modal={true} onHide={() => this.setState({ visible_dlg_souscription: false })}>
                    <div className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-4" style={{ paddingTop: 30 }}>
                            <span className="p-float-label">
                                <Dropdown autoWidth={false} value={this.state.formDataSouscription.idService.id} filter={true} filterBy='label' filterMatchMode='contains'
                                    options={this.state.liste_service}
                                    onChange={(e) => this.setState({ formDataSouscription: this.gestGemsysService.onInputChange(e, 'idService', 'fk', this.state.formDataSouscription) }, () => {
                                        let form = this.state.formDataSouscription;
                                        let montant = this.gestGemsysService.getSelectedData(this.state.liste_service, this.state.formDataSouscription.idService.id).tarif;
                                        form.montantSouscription = montant;
                                        this.setState({ formDataSouscription: form });
                                    })}
                                    appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && this.state.formDataSouscription.idService.id == 0 })} />
                                {this.state.submit && !this.state.formDataSouscription.idService.id && <small className="p-invalid" style={{ color: 'red' }}>La prestation est obligatoire.</small>}
                                <label htmlFor="lieuNaissCli">Prestation *</label>
                            </span>

                        </div>

                        <div className="field col-12 md:col-4" style={{ paddingTop: 30 }}>
                            <span className="p-float-label">
                                <InputNumber id="name" value={this.state.formDataSouscription.montantSouscription} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataSouscription.montantSouscription })}
                                    onChange={(e) => this.setState({ formDataSouscription: this.gestGemsysService.onInputChange(e, 'montantSouscription', '', this.state.formDataSouscription) })}
                                    required min={0} locale="en-US" minFractionDigits={0} />
                                {this.state.submit && !this.state.formDataSouscription.montantSouscription && <small className="p-invalid" style={{ color: 'red' }}>Le tarif est obligatoire.</small>}
                                <label htmlFor="lieuNaissCli">Tarif *</label>
                            </span>
                        </div>

                        <div className="field col-12 md:col-3" style={{ paddingTop: 30 }}>
                            <Button label="Ajouter" loading={this.state.showindicator} icon="pi pi-plus" onClick={(e) => this.enreg_data_souscription()} style={{ width: 120 }} />
                        </div>
                    </div>
                    <DataTable value={this.state.liste_souscription} paginator rows={10} style={{ minHeight: '300px' }} editMode="cell" dataKey='idInsc'
                        resizableColumns="scroll" showGridlines className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                        selection={this.state.selectedSouscription} onSelectionChange={e => this.setState({ selectedSouscription: e.value })}
                        emptyMessage="No data found" alwaysShowPaginator={false} selectionMode="single">
                        <Column body={this.actionTemplateSouscription.bind(this)} header="#" style={{ width: '5%' }} />
                        <Column field="dateSouscription" header="Date" style={{ width: '10%' }} />
                        <Column field="idService.libelleCodif" header="Prestation" />
                        <Column field="montantSouscription" body={(rowData, column) => {
                            return <div>
                                <span>{this.gestGemsysService.formaterValueSep(parseFloat(rowData.montantSouscription))}</span>
                            </div>
                        }} header="Tarif" style={{ width: '15%' }} editor={(options) => this.montantSouscEditor(options)} onCellEditComplete={this.onCellEditComplete} />
                    </DataTable>
                </Dialog>

                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <i className="pi pi-search"></i>
                            <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>
                            <hr />
                            <div className="grid">
                                <div className="col-12 md:col-2">
                                    <label >Classe *</label>
                                </div>
                                <div className="col-12 md:col-6">
                                    <Dropdown options={rclasses} placeholder="Selectionner la classe" value={this.state.rclasse} onChange={(e) => this.setState({ rclasse: e.value }, () => {
                                        this.get_liste_inscrit_for_validation();
                                    })} autoWidth={false} filter filterMode='contains' />
                                </div>
                                <div className="col-12 md:col-4">
                                    <Button label="Rechercher" loading={this.state.showindicator} icon="pi pi-search" onClick={() => this.get_liste_inscrit_for_validation()} style={{ width: 250 }} />
                                </div>
                            </div>
                            <Panel header="Frais">
                                <center>
                                    <div className="grid">
                                        <div className="col-12 md:col-2">
                                            <label style={{ color: 'green', fontWeight: 'bold', fontSize: 18 }}>{this.gestGemsysService.formaterValueSep(this.state.mtTranche1)}</label><br />
                                            <span style={{ color: '#B4BABD' }}>Tranche1</span>
                                        </div>

                                        <div className="col-12 md:col-2">
                                            <label style={{ color: 'green', fontWeight: 'bold', fontSize: 18 }}>{this.gestGemsysService.formaterValueSep(this.state.mtTranche2)}</label><br />
                                            <span style={{ color: '#B4BABD' }}>Tranche2</span>
                                        </div>

                                        <div className="col-12 md:col-2">
                                            <label style={{ color: 'green', fontWeight: 'bold', fontSize: 18 }}>{this.gestGemsysService.formaterValueSep(this.state.mtTranche3)}</label><br />
                                            <span style={{ color: '#B4BABD' }}>Tranche3</span>
                                        </div>

                                        <div className="col-12 md:col-2">
                                            <label style={{ color: 'green', fontWeight: 'bold', fontSize: 18 }}>{this.gestGemsysService.formaterValueSep(this.state.fraisInscription)}</label><br />
                                            <span style={{ color: '#B4BABD' }}>Inscription</span>
                                        </div>

                                        <div className="col-12 md:col-2">
                                            <label style={{ color: 'green', fontWeight: 'bold', fontSize: 18 }}>{this.gestGemsysService.formaterValueSep(this.state.fraisReinscription)}</label><br />
                                            <span style={{ color: '#B4BABD' }}>Réinscription</span>
                                        </div>

                                        <div className="col-12 md:col-2">
                                            <label style={{ color: 'green', fontWeight: 'bold', fontSize: 18 }}>{this.gestGemsysService.formaterValueSep(this.state.fraisBadge)}</label><br />
                                            <span style={{ color: '#B4BABD' }}>Frais badge</span>
                                        </div>
                                    </div>
                                </center>
                            </Panel>
                            <DataTable value={this.state.liste_inscriptions} style={{ minHeight: '700px' }}
                                className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={10}
                                onSelectionChange={e => this.setState({ selectedInscriptions: e.value })} alwaysShowPaginator={false}
                                selection={this.state.selectedInscriptions} editMode="cell" dataKey='idInsc' >
                                <Column selectionMode="multiple" style={{ width: '3%' }} />
                                <Column body={this.actionTemplate.bind(this)} header="Action" style={{ width: '5%' }} />
                                <Column body={this.photoTemplate.bind(this)} header="Photo" style={{ width: '5%' }} />
                                <Column field="matricule.matricule" header="Matricule" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                <Column field="matricule.prenomEleve" body={(data) => {
                                    return <div>
                                        {data.matricule.nomEleve + " " + data.matricule.prenomEleve}
                                    </div>
                                }} header="Nom" style={{ width: '15' }} filter={true} filterMatchMode='contains' />
                                <Column field="numStatut.statutFinancier" header="Statut" style={{ width: '15%' }} filter={true} filterMatchMode='contains' />
                                <Column field="typeInsc" header="Type" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                <Column key="fraisScolarite" field="fraisScolarite" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: rowData.fraisScolarite <= rowData.maxfraisScolarite ? 'green' : 'red' }}>{this.gestGemsysService.formaterValueSep(parseFloat(rowData.fraisScolarite))}</span>
                                    </div>
                                }} header="Scolarité" style={{ width: '10%' }} editor={(options) => this.fraisScolariteEditor(options)} onCellEditComplete={this.onCellEditComplete} />
                                <Column key="fraisInscReinsc" field="fraisInscReinsc" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: rowData.fraisInscReinsc <= rowData.maxfraisInscReinsc ? 'green' : 'red' }}>{this.gestGemsysService.formaterValueSep(parseFloat(rowData.fraisInscReinsc))}</span>
                                    </div>
                                }} header="Inscription" style={{ width: '10%' }} editor={(options) => this.fraisInscReinscEditor(options)} onCellEditComplete={this.onCellEditComplete} />
                                <Column key="numQuitance" field="numQuitance" header="N° Quitance" style={{ width: '10%' }} editor={(options) => this.numQuitanceEditor(options)} onCellEditComplete={this.onCellEditComplete} />
                                {/* <Column key="fraisBadge" field="fraisBadge" body={(rowData, column) => {
                                    return <div> 
                                        <span style={{ color: rowData.fraisBadge <= rowData.maxfraisBadge?'green':'red' }}>{this.gestGemsysService.formaterValueSep(parseFloat(rowData.fraisBadge))}</span>
                                    </div>
                                }} header="Badge" style={{ width: '10%' }} editor={(options) => this.fraisBadgeEditor(options)} onCellEditComplete={this.onCellEditComplete} filter={true} filterMatchMode='contains' /> */}
                            </DataTable>
                            <br />
                            <center>
                                <div className="grid" style={{ width: 400 }}>
                                    <div className="col"><Button label="Supprimer" loading={this.state.showindicator} className="p-button-danger" icon="pi pi-times" onClick={() => this.conf_delete_inscription()} /></div>
                                    <div className="col"><Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={() => this.valider_inscriptions()} /></div>
                                </div>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
