import React, { Component } from 'react';
import GestGemsysService from '../service/GestGemsysService';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { InputNumber } from 'primereact/inputnumber';
import { SplitButton } from 'primereact/splitbutton';
import { Toolbar } from 'primereact/toolbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

export class FormEncaissement extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            rlibelle: 'Tous',
            rclasse: 'Tous',
            rrefVers: 'Tous',
            liste_type: [],
            liste_vers_bulk: [],
            liste_membre: [],
            liste_famille: [],
            liste_item_export: [
                {
                    label: 'Exporter en CVS',
                    icon: 'pi pi-download',
                    command: (e) => {
                        this.exportCSV();
                    }
                },
                {
                    label: 'Exporter en XLS',
                    icon: 'pi pi-file-excel',
                    command: (e) => {
                        this.exportExcel();
                    }
                },
                {
                    label: 'Exporter en PDF',
                    icon: 'pi pi-file-pdf',
                    command: (e) => {
                        this.exportPdf();
                    }
                },
            ],
            libelles: [
                { label: 'Frais scolarité', value: 'Frais scolarité' },
                { label: 'Frais inscription', value: 'Frais inscription' },
                { label: 'Frais réinscription', value: 'Frais réinscription' },
                { label: 'Frais badge', value: 'Frais badge' },
                { label: 'Frais APEAE', value: 'Frais APEAE' },
                { label: 'Frais Cyber', value: 'Frais Cyber' },
                { label: 'Ancien bon', value: 'Ancien bon' },
                { label: 'Subvention', value: 'Subvention' },
                { label: 'Autre recette', value: 'Autre recette' }
            ],
            rlibelles: [
                { label: 'Libelle', value: 'Tous' },
                { label: 'Frais scolarité', value: 'Frais scolarité' },
                { label: 'Frais inscription', value: 'Frais inscription' },
                { label: 'Frais réinscription', value: 'Frais réinscription' },
                { label: 'Frais badge', value: 'Frais badge' },
                { label: 'Frais APEAE', value: 'Frais APEAE' },
                { label: 'Frais Cyber', value: 'Frais Cyber' },
                { label: 'Ancien bon', value: 'Ancien bon' },
                { label: 'Subvention', value: 'Subvention' },
                { label: 'Autre recette', value: 'Autre recette' }
            ],
            numRecu: '',
            montVers: '',
            typeVers: '',
            dateVers: new Date(),
            libVers: 'Frais scolarité',
            heureVers: '',
            idTypeVers: null,
            idInsc: '',
            cmtVers: '',
            idEtabl: '',
            iduser: '',
            refVers: '',
            classe: null,
            source: null,
            ridFamille: null,
            inscrit: [],
            msg: '',
            modif: false,
            visible: false,
            recherche: false,
            liste_versements: null,
            semestres: [],
            selectedVers: null,
            selectedMembre: null,
            showindicator: false,
            liste_etudiants_by_classe: [],
            retudiants: [],
            total: '0',
            totalRestant: 0,
            resultat: 0,
            submit: false,
            visible_dlg_form: false,
            visible_dlg_famille: false,
        };
        this.edit_encaissement = this.edit_encaissement.bind(this);
        this.toogleDlg = this.toogleDlg.bind(this);
        this.conf_delete_encaissement = this.conf_delete_encaissement.bind(this);
        this.gemsysService = new GestGemsysService();
        this.export = this.export.bind(this);
    }


    componentDidMount() {
        this.get_liste_versements();
        this.get_liste_type_recette();
        this.get_liste_famille();
        this.gemsysService.getClasse().then(data => this.setState({ semestres: data }));
    }

    /* Récupération de la liste globale des familles */
    get_liste_famille() {
        this.setState({ showindicator: true, liste_famille: [] });
        this.gemsysService.get_liste_famille().then(data => {
            this.setState({ showindicator: false });
            if (data !== undefined && data !== null) {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.id, label: x.libelleFamille }
                    });
                    this.setState({ liste_famille: liste });
                }
            }
        });
    }

    /* Récupération de la liste globale des types */
    get_liste_type_recette() {
        this.setState({ showindicator: true, liste_type: [] });
        this.gemsysService.get_liste_codification('TYPE_RECETTE').then(data => {
            this.setState({ showindicator: false });
            if (data !== undefined && data !== null) {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.id, label: x.libelleCodif, id: x.id }
                    });
                    this.setState({ liste_type: liste });
                }
            }
        });
    }

    get_liste_versements() {
        let rclasse = "Tous";
        let rrefVers = "Tous";
        let rlibelle = "Tous";

        let spd1 = moment(this.state.pd1).format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2).format('DD/MM/YYYY');
        if (this.state.rlibelle !== 'Tous' && this.state.rlibelle !== "" && this.state.rlibelle !== undefined) {
            rlibelle = this.state.rlibelle;
        }
        if (this.state.rclasse !== 'Tous' && this.state.rclasse !== "" && this.state.rclasse !== undefined) {
            rclasse = this.state.rclasse;
        }
        if (this.state.rrefVers !== 'Tous' && this.state.rrefVers !== "" && this.state.rrefVers !== undefined) {
            rrefVers = this.state.rrefVers;
        }
        this.setState({ showindicator: true });
        this.gemsysService.get_liste_versements_by_criteria(spd1, spd2, rlibelle, rclasse, rrefVers).then(data => this.setState({ liste_versements: data }, () => {
            let initialValue = 0;
            let tt = 0;
            if (data !== null && data !== undefined) {
                tt = data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.montVers
                }, initialValue);
            }
            this.setState({ showindicator: false, total: this.gemsysService.formaterValueSep(parseFloat(tt)) });
        }));
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_encaissement();
            this.get_liste_versements();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    edit_encaissement(data) {
        if (data != null && data != undefined) {
            this.setState({ numRecu: data.numRecu });
            this.setState({ montVers: data.montVers });
            this.setState({ typeVers: data.typeVers });
            this.setState({ dateVers: new Date(moment(data.dateVers, 'DD/MM/YYYY').format('YYYY-MM-DD')) });
            this.setState({ libVers: data.libVers });
            this.setState({ idTypeVers: data.idTypeVers });
            this.setState({ cmtVers: data.cmtVers });
            this.setState({ modif: true });
            this.setState({ selectedVers: data });
            window.scrollTo(0, 0);
        }
    }

    annule_encaissement() {
        this.setState({ numRecu: "" });
        this.setState({ montVers: 0 });
        this.setState({ typeVers: "" });
        this.setState({ anneeVers: "" });
        this.setState({ idTypeVers: null });
        this.setState({ libVers: "" });
        this.setState({ idInsc: "" });
        this.setState({ cmtVers: "" });
        this.setState({ source: null });
        this.setState({ classe: null });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_encaissement() {
        if (this.state.idTypeVers == null || this.state.montVers == "" || this.state.libVers == "" || this.state.source == null || this.state.source == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            if (this.state.modif == false) {
                let check = false;
                let mtRestant = 0;
                if (this.state.libVers == 'Frais scolarité') {
                    mtRestant = this.gemsysService.getSelectedData(this.state.liste_etudiants_by_classe, this.state.source).scolRestant;
                    check = true;
                } else if (this.state.libVers == 'Frais inscription' || this.state.libVers == 'Frais réinscription') {
                    mtRestant = this.gemsysService.getSelectedData(this.state.liste_etudiants_by_classe, this.state.source).inscRestant;
                    check = true;
                }
                if (check == true && this.state.montVers > mtRestant) {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Le montant saisi est supérieur au montant restant' });
                    return;
                }
                let encaissement = {
                    numRecu: '0',
                    montVers: this.state.montVers,
                    typeVers: this.state.typeVers,
                    dateVers: moment(this.state.dateVers, 'DD/MM/YYYY').format('DD/MM/YYYY'),
                    anneeVers: this.gemsysService.get_rannee(),
                    libVers: this.state.libVers,
                    idTypeVers: { id: this.state.idTypeVers },
                    idInsc: this.state.source,
                    cmtVers: this.state.cmtVers,
                    refVers: this.state.refVers
                };
                this.setState({ showindicator: true });
                this.gemsysService.AddVersement(encaissement).then(reponse => {
                    if (reponse != undefined && reponse != null) {
                        this.resultat(reponse.code, reponse.contenu);
                        if (reponse.code == '200') {
                            this.gemsysService.generer_liste_recu(reponse.id);
                        }
                    }
                });
            } else {
                let encaissement = this.state.selectedVers;
                encaissement.montVers = this.state.montVers;
                encaissement.typeVers = this.state.typeVers;
                encaissement.dateVers = moment(this.state.dateVers, 'DD/MM/YYYY').format('DD/MM/YYYY');
                encaissement.libVers = this.state.libVers;
                encaissement.cmtVers = this.state.cmtVers;
                encaissement.idTypeVers = { id: this.state.idTypeVers };
                this.setState({ showindicator: true });
                this.gemsysService.UpdateVersement(encaissement).then(reponse => {
                    if (reponse != undefined && reponse != null) {
                        this.resultat(reponse.code, reponse.contenu);
                    }
                });
            }
        }
    }

    enreg_versement_famille() {

    }

    get_id_inscrit(codeClasse, matricule) {
        this.gemsysService.get_id_inscrit_by_codeClasseAndMatricule(codeClasse, matricule).then(data => this.setState({ inscrit: data }, () => {
            if (this.state.inscrit != null && this.state.inscrit != undefined) {
                let lIdInscrit = this.state.inscrit.map(x => {
                    return { idInsc: x.idInsc, label: x.typeInsc }
                });
                this.setState({ idInsc: lIdInscrit[0] });
            }
        }));
    }

    /* Affichage de la boite de dialogue pour l'ajout d'une nouvelle adresse */
    toogleDlg() {
        this.annule_encaissement();
        this.setState({ visible_dlg_form: !this.state.visible_dlg_form });
    }

    toogle_dlg_famille() {
        this.setState({ visible_dlg_famille: !this.state.visible_dlg_famille, montVers: 0 });
    }

    /* Zone gauche de la toolbar */
    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nouveau" icon="pi pi-plus" className="p-button-success mr-2" onClick={this.toogleDlg} style={{ width: 115 }} />
                    <Button label="Famille" icon="pi pi-users" className="p-button-warning" onClick={(e) => this.toogle_dlg_famille(this.state.selectedData)} style={{ width: 115 }} />
                </div>
            </React.Fragment>
        )

    }

    /* Zone droite de la toolbar */
    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> :
                    <SplitButton label="Exporter" icon="pi pi-download" className="p-button-help" onClick={this.exportExcel} model={this.state.liste_item_export} />
                }
            </React.Fragment>
        )
    }

    /* Exportation du tableau  en fichier PDF */
    exportPdf = () => {
        const cols = [
            { field: 'FAMILLE', header: 'FAMILLE' },
            { field: 'ADRESSE', header: 'ADRESSE' },
            { field: 'CONTACT', header: 'CONTACT' }
        ];
        let liste_data = this.state.liste_versements.length > 0 ? this.state.liste_versements.map(x => {
            return { 'FAMILLE': x.libelleFamille, 'CONTACT': x.contactFamille, 'ADRESSE': x.adrFamille }
        }) : [];
        const exportColumns = cols.map(col => ({ title: col.header, dataKey: col.field }));
        import('jspdf').then(jsPDF => {
            import('jspdf-autotable').then(() => {
                var doc = new jsPDF.default(0, 0);
                doc.autoTable(exportColumns, liste_data);
                doc.save('FAMILLES.pdf');
            })
        })
    }

    /* Exportation du tableau  en fichier XLSX */
    exportExcel = () => {
        let liste_data = this.state.liste_versements.length > 0 ? this.state.liste_versements.map(x => {
            return { 'FAMILLE': x.libelleFamille, 'CONTACT': x.contactFamille, 'ADRESSE': x.adrFamille }
        }) : [];
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(liste_data);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, 'LISTE_ENCAISSEMENT');
        });
    }


    /* Enregistrer le fichier sur le client */
    saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }

    /* Exportation du tableau contenant la liste des quartiers en fichier CSV */
    exportCSV = () => {
        this.dt.exportCSV("Liste_encaissement");
    }

    export() {
        this.dt.exportCSV();
    }

    header_encaissement() {
        return <div>
            <Button style={{ width: 120 }} label="Exporter" className="p-button-help" icon="pi pi-external-link" onClick={this.export} />
        </div>
    }

    header_total() {
        return <div>
            <label style={{ fontSize: 18, color: 'green', fontWeight: 'bold' }} >{this.state.total} GNF</label>
        </div>
    }

    actionTemplate(rowData) {
        const items = [
            {
                label: 'Modifier',
                icon: 'pi pi-pencil',
                command: (e) => {
                    this.edit_encaissement(rowData);
                }
            },
            {
                label: 'Supprimer',
                icon: 'pi pi-times',
                command: (e) => {
                    this.conf_delete_encaissement(rowData);
                }
            },
            {
                label: 'Editer reçu',
                icon: 'pi pi-print',
                command: (e) => {
                    this.setState({ selectedVers: rowData, showindicator: true });
                    this.gemsysService.generer_liste_recu(rowData.numRecu).then(reponse => {
                        this.setState({ showindicator: false });
                    });
                }
            }
        ]
        return <SplitButton loading={this.state.showindicator} style={{ width: 95 }} label="Reçu" icon="pi pi-print" onClick={(e) => {
            this.setState({ selectedVers: rowData, showindicator: true });
            this.gemsysService.generer_liste_recu(rowData.numRecu).then(reponse => {
                this.setState({ showindicator: false });
            });
        }} model={items} />


    }

    get_liste_etudiants_by_classe(codeClasse) {
        this.gemsysService.getliste_inscrit_for_versement(codeClasse).then(data => {
            if (data !== null && data !== undefined) {
                let liste = data.map(x => {
                    return { value: x.idInsc, label: x.matricule.nomEleve + ' ' + x.matricule.prenomEleve, scolRestant: x.fraisScolRestant, inscRestant: x.fraisInscRestant, matricule: x.matricule.matricule }
                });
                let liste_etudiants_by_classe = [{ value: '-1', label: 'Non applicable', scolRestant: 0, inscRestant: 0, matricule: '-1' }].concat(liste);
                let retudiants = [{ value: 'Tous', label: 'Elève' }].concat(liste);

                this.setState({
                    liste_etudiants_by_classe: liste_etudiants_by_classe,
                    retudiants: retudiants,
                })

            }
        });
    }

    delete_encaissement() {
        if (this.state.selectedVers == null || this.state.selectedVers == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.gemsysService.DeleteVersement(this.state.selectedVers).then(data => this.resultat(data.code, data.contenu));
        }
    }

    conf_delete_encaissement(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedVers: data });
            this.setState({ msg: data.numRecu });
            this.setState({ visible: true });
        }
    }

    photoTemplate(rowData, column) {
        let fic = rowData.matricule.photo;
        if (fic === undefined || fic === null || fic === '') {
            fic = 'nophoto.png';
        }
        return <img src={this.gemsysService.download_file(fic, 'photos/')} alt='Photo' style={{ width: 40, height: 40 }} />
    }

    /* Récupération de la liste globale des membres */
    get_liste_membre() {
        this.setState({ showindicator: true,totalRestant: 0 });
        this.gemsysService.get_liste_membres_famille(this.state.ridFamille).then(data => {
            this.setState({ liste_membre: data, showindicator: false });
            if (data !== null && data !== undefined) {
                if (data.length > 0) {
                    let initialValue = 0;
                    let totalRestant = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.ttrest
                    }, initialValue);
                    this.setState({totalRestant: totalRestant, resultat: totalRestant});
                }
            }
        });
    }

    repartition_montant() {
        let ttrest = this.state.montVers;
        let liste = this.state.liste_membre;
        let liste_vers = [];
        let ttreparti = 0;
         
        for (let i = 0; i < this.state.liste_membre.length; i++) {
            let montant = liste[i].firest;
            let libVers = liste[i].idInsc.typeInsc=='Inscription'?'Frais inscription':'Frais réinscription';
            if (montant > 0) {
                if (montant >= ttrest) {
                    liste_vers.push(
                        {
                            numRecu: 'I' + i,
                            montVers: ttrest,
                            typeVers: 'Espèce',
                            dateVers: moment(this.state.dateVers, 'DD/MM/YYYY').format('DD/MM/YYYY'),
                            anneeVers: this.gemsysService.get_rannee(),
                            libVers: libVers,
                            idTypeVers: { id: this.gemsysService.getSelectedDatabyLabel(this.state.liste_type, libVers) },
                            idInsc: liste[i].idInsc.idInsc,
                            cmtVers: 'Versement groupé',
                            refVers: 'Montant de ' + this.state.montVers,
                            anneeVers: this.gemsysService.get_rannee(),
                            idEtabl: this.gemsysService.get_userconnected().idEtabl.idEtabl,
                            iduser: this.gemsysService.get_userconnected().numUtil
                        }
                    );
                    ttreparti = ttreparti+ttrest;
                    ttrest = 0;
                    break;
                } else {
                    ttreparti = ttreparti+montant;
                    ttrest = ttrest - montant;
                    liste_vers.push(
                        {
                            numRecu: 'I' + i,
                            montVers: montant,
                            typeVers: 'Espèce',
                            dateVers: moment(this.state.dateVers, 'DD/MM/YYYY').format('DD/MM/YYYY'),
                            anneeVers: this.gemsysService.get_rannee(),
                            libVers: libVers,
                            idTypeVers: { id: this.gemsysService.getSelectedDatabyLabel(this.state.liste_type, libVers) },
                            idInsc: liste[i].idInsc.idInsc,
                            cmtVers: 'Versement groupé',
                            refVers: 'Montant de ' + this.state.montVers,
                            anneeVers: this.gemsysService.get_rannee(),
                            idEtabl: this.gemsysService.get_userconnected().idEtabl.idEtabl,
                            iduser: this.gemsysService.get_userconnected().numUtil
                        }
                    );
                }
            }
        }

        for (let i = 0; i < this.state.liste_membre.length; i++) {
            let libVers = 'Frais badge';
            let montant = liste[i].fbrest;
            if (montant > 0) {
                if (montant >= ttrest) {
                    liste_vers.push(
                        {
                            numRecu: 'B' + i,
                            montVers: ttrest,
                            typeVers: 'Espèce',
                            dateVers: moment(this.state.dateVers, 'DD/MM/YYYY').format('DD/MM/YYYY'),
                            anneeVers: this.gemsysService.get_rannee(),
                            libVers: libVers,
                            idTypeVers: { id: this.gemsysService.getSelectedDatabyLabel(this.state.liste_type, libVers) },
                            idInsc: liste[i].idInsc.idInsc,
                            cmtVers: 'Versement groupé',
                            refVers: 'Montant de ' + this.state.montVers,
                            anneeVers: this.gemsysService.get_rannee(),
                            idEtabl: this.gemsysService.get_userconnected().idEtabl.idEtabl,
                            iduser: this.gemsysService.get_userconnected().numUtil
                        }
                    );
                    ttreparti += ttrest;
                    break;
                } else {
                    ttreparti += montant;
                    ttrest = ttrest - montant;
                    liste_vers.push(
                        {
                            numRecu: 'B' + i,
                            montVers: montant,
                            typeVers: 'Espèce',
                            dateVers: moment(this.state.dateVers, 'DD/MM/YYYY').format('DD/MM/YYYY'),
                            anneeVers: this.gemsysService.get_rannee(),
                            libVers: libVers,
                            idTypeVers: { id: this.gemsysService.getSelectedDatabyLabel(this.state.liste_type, libVers) },
                            idInsc: liste[i].idInsc.idInsc,
                            cmtVers: 'Versement groupé',
                            refVers: 'Montant de ' + this.state.montVers,
                            anneeVers: this.gemsysService.get_rannee(),
                            idEtabl: this.gemsysService.get_userconnected().idEtabl.idEtabl,
                            iduser: this.gemsysService.get_userconnected().numUtil
                        }
                    );
                }
            }
        }

        for (let i = 0; i < this.state.liste_membre.length; i++) {
            let libVers = 'Frais scolarité';
            let montant = liste[i].fsrest;
            if (montant > 0) {
                if (montant >= ttrest) {
                    liste_vers.push(
                        {
                            numRecu: 'S' + i,
                            montVers: ttrest,
                            typeVers: 'Espèce',
                            dateVers: moment(this.state.dateVers, 'DD/MM/YYYY').format('DD/MM/YYYY'),
                            anneeVers: this.gemsysService.get_rannee(),
                            libVers: libVers,
                            idTypeVers: { id: this.gemsysService.getSelectedDatabyLabel(this.state.liste_type, libVers) },
                            idInsc: liste[i].idInsc.idInsc,
                            cmtVers: 'Versement groupé',
                            refVers: 'Montant de ' + this.state.montVers,
                            anneeVers: this.gemsysService.get_rannee(),
                            idEtabl: this.gemsysService.get_userconnected().idEtabl.idEtabl,
                            iduser: this.gemsysService.get_userconnected().numUtil
                        }
                    );
                    ttreparti = ttreparti+ ttrest;
                    ttrest=0;
                    break;
                } else {
                    ttreparti += montant;
                    ttrest = ttrest - montant;
                    liste_vers.push(
                        {
                            numRecu: 'S' + i,
                            montVers: montant,
                            typeVers: 'Espèce',
                            dateVers: moment(this.state.dateVers, 'DD/MM/YYYY').format('DD/MM/YYYY'),
                            anneeVers: this.gemsysService.get_rannee(),
                            libVers: libVers,
                            idTypeVers: { id: this.gemsysService.getSelectedDatabyLabel(this.state.liste_type, libVers) },
                            idInsc: liste[i].idInsc.idInsc,
                            cmtVers: 'Versement groupé',
                            refVers: 'Montant de ' + this.state.montVers,
                            anneeVers: this.gemsysService.get_rannee(),
                            idEtabl: this.gemsysService.get_userconnected().idEtabl.idEtabl,
                            iduser: this.gemsysService.get_userconnected().numUtil
                        }
                    );
                }
            }
        }
        let tr=this.state.totalRestant>=this.state.montVers?this.state.totalRestant-ttreparti:this.state.montVers-ttreparti;
        this.setState({ resultat: tr })
    }

    render() {
        let semestres = null;

        let headerGroup = <ColumnGroup>
            <Row>
                <Column header="Photo" rowSpan={2} />
                <Column header="Prénoms et nom" rowSpan={2} />
                <Column header="Classe" rowSpan={2} />
                <Column header="Scolarité" colSpan={2} />
                <Column header="Inscription" colSpan={2} />
                <Column header="Badge" colSpan={2} />
                <Column header="Restant" rowSpan={2} />
            </Row>
            <Row>
                <Column header='Payé' field="fspaye" />
                <Column header='Restant' field="fsrest" />

                <Column header='Payé' field="fipaye" />
                <Column header='Restant' field="firest" />

                <Column header='Payé' field="fbpaye" />
                <Column header='Restant' field="fbrest" />
            </Row>
        </ColumnGroup>;

        if (this.state.semestres != null && this.state.semestres != undefined) {
            let lc = this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ? 'S' : '';
            semestres = this.state.semestres.map(x => {
                return { value: x.codeClasse, label: lc + x.libClasse + " " + x.optClasse }
            });
        }


        let rclasses = [{ value: 'Tous', label: 'Classe' }].concat(semestres);

        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_encaissement();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                {/* Toolbar contenant les boutons d'ajout, de suppression et d'exportation */}
                <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate} style={{ paddingTop: 5, paddingBottom: 5, backgroundColor: '#fff' }} />
                <br />

                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                {/* Boite de dialogue d'ajout et de modification d'une famille  */}
                <Dialog visible={this.state.visible_dlg_form} style={{ width: '70%' }} header={this.state.modif_data == true ? "Modification" : "Ajout"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form: false })}
                    footer={<>
                        <Button label="Fermer" icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.toogleDlg()} />
                        <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_encaissement()} style={{ width: 120 }} />
                    </>}>
                    <div className="p-fluid formgrid grid card" style={{ paddingBlock: 25, backgroundColor: '#fbfcfc', border: '3px solid #ebedef ' }}>
                        <div className="field col-6" style={{ padding: 4 }}>
                            <span className="p-float-label">
                                <Calendar placeholder={moment(new Date()).format('DD/MM/YYYY')} dateFormat="dd/mm/yy" value={this.state.dateVers} onChange={(e) => this.setState({ dateVers: e.target.value })} />
                                <label htmlFor="lieuNaissCli">Date *</label>
                            </span>
                            {this.state.submit && !this.state.dateVers && <small className="p-invalid" style={{ color: 'red' }}>La date est obligatoire.</small>}
                        </div>

                        <div className="field col-6" style={{ padding: 4 }}>
                            <span className="p-float-label">
                                <Dropdown options={this.state.liste_type} value={this.state.idTypeVers} onChange={(e) => this.setState({ idTypeVers: e.target.value, typeVers: 'Espèce' }, () => {
                                    this.setState({ libVers: this.gemsysService.getSelectedData(this.state.liste_type, this.state.idTypeVers).label })
                                })} autoWidth={false} filter={true} filterBy='label' filterMatchMode='contains' panelStyle={{ width: '300px' }} />
                                <label htmlFor="lieuNaissCli">Type *</label>
                            </span>
                            {this.state.submit && !this.state.libVers && <small className="p-invalid" style={{ color: 'red' }}>Le type est obligatoire.</small>}
                        </div>

                        <div className="field col-6" style={{ padding: 4 }}>
                            <span className="p-float-label">
                                <Dropdown options={semestres} filter={true} optionLabel='label' value={this.state.classe} onChange={(e) => {
                                    this.setState({ classe: e.value }, () => {
                                        this.get_liste_etudiants_by_classe(this.state.classe);
                                    })
                                }} autoWidth={false} filterBy='label' filterMatchMode='contains' panelStyle={{ width: '300px' }} />
                                <label htmlFor="lieuNaissCli">Classe *</label>
                            </span>
                        </div>

                        <div className="field col-6" style={{ padding: 4 }}>
                            <span className="p-float-label">
                                <Dropdown options={this.state.liste_etudiants_by_classe} optionLabel="label" filter={true} value={this.state.source} onChange={(e) => {
                                    this.setState({ source: e.target.value })
                                }} autoWidth={false} filterBy='label' filterMatchMode='contains' panelStyle={{ width: '550px' }}
                                    itemTemplate={(option) => {
                                        if (!option.value) {
                                            return option.label;
                                        } else {
                                            return (
                                                <div className="grid" >
                                                    <div className="col-12 md:col-3">
                                                        <label>{option.matricule}</label>
                                                    </div>
                                                    <div className="col-12 md:col-5">
                                                        <label>{option.label}</label>
                                                    </div>
                                                    <div className="col-12 md:col-2">
                                                        <label style={{ fontWeight: 'bold' }}>{this.gemsysService.formaterValueSep(option.scolRestant)}</label>
                                                    </div>
                                                    <div className="col-12 md:col-2">
                                                        <label style={{ fontWeight: 'bold' }}>{this.gemsysService.formaterValueSep(option.inscRestant)}</label>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    }} />
                                <label htmlFor="lieuNaissCli">Source *</label>
                            </span>
                            {this.state.submit && !this.state.source && <small className="p-invalid" style={{ color: 'red' }}>La source est obligatoire.</small>}
                        </div>

                        <div className="field col-6" style={{ padding: 4 }}>
                            <span className="p-float-label">
                                <InputNumber id="montVers" value={this.state.montVers} onChange={(e) => this.setState({ montVers: e.value })} />
                                <label htmlFor="lieuNaissCli">Montant *</label>
                            </span>
                            {this.state.submit && !this.state.montVers && <small className="p-invalid" style={{ color: 'red' }}>La montant est obligatoire.</small>}
                        </div>

                        <div className="field col-6" style={{ padding: 4 }}>
                            <span className="p-float-label">
                                <InputText id="cmtVers" value={this.state.cmtVers} onChange={(e) => this.setState({ cmtVers: e.target.value })} />
                                <label htmlFor="lieuNaissCli">Commentaire</label>
                            </span>
                        </div>

                    </div>
                </Dialog>

                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">

                            <div className="grid">
                                <i className="pi pi-search"></i>
                                <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>
                                <div className="col-12">
                                    <div className="grid">
                                        <div className="col-12 md:col-2">
                                            <Calendar id="pd1" placeholder={moment(new Date()).format('DD/MM/YYYY')} dateFormat="dd/mm/yy" value={this.state.pd1} onChange={(e) => this.setState({ pd1: e.target.value })} />
                                        </div>
                                        <div className="col-12 md:col-2">
                                            <Calendar id="pd2" placeholder={moment(new Date()).format('DD/MM/YYYY')} dateFormat="dd/mm/yy" value={this.state.pd2} onChange={(e) => this.setState({ pd2: e.target.value })} />
                                        </div>
                                        <div className="col-12 md:col-2" >
                                            <Dropdown options={this.state.rlibelles} value={this.state.rlibelle} placeholder="Selectionner libelle" filter={true} onChange={(e) => this.setState({ rlibelle: e.target.value })} autoWidth={false} />
                                        </div>
                                        <div className="col-12 md:col-2" >
                                            <Dropdown options={rclasses} value={this.state.rclasse} optionLabel="label" placeholder="Selectionner classe" filter={true} onChange={(e) => {
                                                this.setState({ rclasse: e.value }, () => {
                                                    this.get_liste_versements();
                                                    this.get_liste_etudiants_by_classe(this.state.rclasse);
                                                })
                                            }} autoWidth={false} />
                                        </div>
                                        <div className="col-12 md:col-2" >
                                            <Dropdown options={this.state.retudiants} value={this.state.rrefVers} placeholder="Non applicable" optionLabel="label" filter={true} onChange={(e) => this.setState({ rrefVers: e.target.value })} autoWidth={false} />
                                        </div>
                                        <div className='col-12 md:col-2' >
                                            <Button icon='pi pi-search' loading={this.state.showindicator} label='Rechercher' onClick={() => this.get_liste_versements()} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <DataTable value={this.state.liste_versements} paginator={true} rows={20} alwaysShowPaginator={true} paginatorPosition="bottom"
                                selectionMode="single" paginatorRight={this.header_total()}
                                onRowClick={e => this.setState({ selectedVers: e.data })} onRowSelect={e => this.setState({ selectedVers: e.data })} style={{ minHeight: '500px' }}
                                responsive={true} selection={this.state.selectedVers} ref={(el) => { this.dt = el; }} exportFilename="liste_des_encaissements" csvSeparator=';'
                                className="p-datatable-gridlines" showGridlines responsiveLayout="scroll">
                                <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '7%' }} />
                                <Column field="dateVers" header="Date" style={{ width: '8%' }} sortable={true} />
                                <Column field="libVers" header="Libellé " style={{ width: '13%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="matrVers" header="Matricule" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                <Column field="nomVers" body={(rowData) => {
                                    return <div>
                                        <span>{rowData.nomVers}</span>
                                    </div>
                                }} header="Nom" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="classeVers" body={(rowData) => {
                                    return <div>
                                        <span>{rowData.classeVers}</span>
                                    </div>
                                }} header="Classe" style={{ width: '17%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="cmtVers" header="Commentaire" style={{ width: '15%' }} filter={true} filterMatchMode='contains' />
                                <Column field="montVers" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: 'green' }}>{this.gemsysService.formaterValueSep(parseFloat(rowData.montVers))}</span>
                                    </div>
                                }} header="Montant" style={{ width: '10%' }} sortable={true} />
                            </DataTable>
                        </div>
                    </div>
                </div>

                {/* Boite de dialogue d'affichage des détails pour impression en bloc */}
                <Dialog visible={this.state.visible_dlg_famille} style={{ width: '90%' }} header="Encaissement par famille" modal onHide={(e) => this.setState({ visible_dlg_famille: false })} appendTo={document.body}>
                    <div className="card card-w-title p-fluid" style={{ backgroundColor: '#fbfcfc', paddingBlock: 5 }}>
                        <div className="grid">
                            <div className="col-12 md:col-4" style={{ paddingTop: 30 }}>
                                <span className="p-float-label">
                                    <div className="p-inputgroup">
                                        <Dropdown options={this.state.liste_famille} value={this.state.ridFamille} onChange={(e) => this.setState({ ridFamille: e.value }, () => {
                                            this.get_liste_membre();
                                        })} autoWidth={false} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' panelStyle={{ width: '400px' }} />
                                        <Button icon='pi pi-search' style={{ width: 35 }} onClick={() => {
                                            this.get_liste_membre();
                                        }} loading={this.state.showindicator} />

                                        <label htmlFor="lieuNaissCli">Famille *</label>
                                    </div>
                                </span>
                            </div>

                            <div className="col-12 md:col-2" style={{ paddingTop: 30 }}>
                                <span className="p-float-label">
                                    <Calendar placeholder={moment(new Date()).format('DD/MM/YYYY')} dateFormat="dd/mm/yy" value={this.state.dateVers} onChange={(e) => this.setState({ dateVers: e.target.value })} />
                                    <label htmlFor="lieuNaissCli">Date *</label>
                                </span>
                                {this.state.submit && !this.state.dateVers && <small className="p-invalid" style={{ color: 'red' }}>La date est obligatoire.</small>}
                            </div>
                            <div className="col-12 md:col-2" style={{ paddingTop: 30 }}>
                                <span className="p-float-label">
                                    <InputNumber id="montVers" value={this.state.montVers} onChange={(e) => this.setState({ montVers: e.value },()=>{
                                        this.repartition_montant()
                                    })} onBlur={(e) => this.repartition_montant()} />
                                    <label htmlFor="lieuNaissCli">Montant *</label>
                                </span>
                                {this.state.submit && !this.state.montVers && <small className="p-invalid" style={{ color: 'red' }}>La montant est obligatoire.</small>}
                            </div>
                            <div className="col-12 md:col-2" style={{ paddingTop: 30 }}>

                                {/* <InputNumber readOnly value={this.state.totalRestant} onChange={(e) => this.setState({ totalRestant: e.value })} style={{color: 'red'}} /> */}
                                <div style={{ marginTop: -22 }}>
                                    <label style={{ fontSize: 12, color: "#333" }}> {this.state.totalRestant < this.state.montVers ? 'Monnaie' : 'Restant'} </label>
                                    <br style={{ margin: 0 }} />
                                    <div style={{ paddingLeft: 5, backgroundColor: '#e1f8a3', width: 180, height: 37, marginTop: 3, borderRadius: 10, border: '1px solid #0b662a' }}>
                                        <label style={{ color: 'red', fontWeight: 'bold', fontSize: 28, marginTop: -5 }}>{this.gemsysService.formaterValueSep(this.state.resultat)}</label>
                                    </div>
                                </div>

                            </div>
                            <div className="col-12 md:col-2" style={{ paddingTop: 30 }}>
                                <Button label="Valider" loading={this.state.showindicator} icon="pi pi-plus" onClick={(e) => this.enreg_versement_famille()} style={{ width: 100 }} className="p-button-success mr-2" />
                            </div>
                        </div>
                    </div>
                    {/* Tableau contenant la liste des membres */}
                    <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_membre} resizableColumns="scroll"
                        paginator rows={8} showGridlines className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                        selection={this.state.selectedMembre} onSelectionChange={e => this.setState({ selectedMembre: e.value })}
                        emptyMessage="No data found" alwaysShowPaginator={false} selectionMode="single"
                        headerColumnGroup={headerGroup} style={{ marginTop: -20 }}>
                        <Column body={this.photoTemplate.bind(this)} header="Photo" style={{ width: '5%' }} exportable={false} />
                        <Column field="matricule.prenomEleve" body={(rowData, column) => {
                            return <div>
                                <span>{rowData.matricule.prenomEleve} {rowData.matricule.nomEleve}</span>
                            </div>
                        }} header="Prénoms et Nom" filter={true} filterMatchMode='contains' style={{ width: '12%' }} />
                        <Column field="classe" header="Classe" filter={true} filterMatchMode='contains' />

                        <Column field="fspaye" body={(rowData, column) => {
                            return <div>
                                <span style={{ color: '#000', fontWeight: 'bold' }}>{this.gemsysService.formaterValueSep(parseFloat(rowData.fspaye))}</span>
                            </div>
                        }} header="Scol Payé" style={{ width: '12%' }} sortable={true} />
                        <Column field="fsrest" body={(rowData, column) => {
                            return <div>
                                <span style={{ color: 'red', fontWeight: 'bold' }}>{this.gemsysService.formaterValueSep(parseFloat(rowData.fsrest))}</span>
                            </div>
                        }} header="Scol. restant" style={{ width: '12%' }} sortable={true} />

                        <Column field="fipaye" body={(rowData, column) => {
                            return <div>
                                <span style={{ color: '#000', fontWeight: 'bold' }}>{this.gemsysService.formaterValueSep(parseFloat(rowData.fipaye))}</span>
                            </div>
                        }} header="Insc Payé" style={{ width: '12%' }} sortable={true} />
                        <Column field="firest" body={(rowData, column) => {
                            return <div>
                                <span style={{ color: 'red', fontWeight: 'bold' }}>{this.gemsysService.formaterValueSep(parseFloat(rowData.firest))}</span>
                            </div>
                        }} header="Insc. restant" style={{ width: '12%' }} sortable={true} />

                        <Column field="fbpaye" body={(rowData, column) => {
                            return <div>
                                <span style={{ color: '#000', fontWeight: 'bold' }}>{this.gemsysService.formaterValueSep(parseFloat(rowData.fbpaye))}</span>
                            </div>
                        }} header="Badge Payé" style={{ width: '12%' }} sortable={true} />
                        <Column field="fbrest" body={(rowData, column) => {
                            return <div>
                                <span style={{ color: 'red', fontWeight: 'bold' }}>{this.gemsysService.formaterValueSep(parseFloat(rowData.fbrest))}</span>
                            </div>
                        }} header="Badge restant" style={{ width: '12%' }} sortable={true} />

                        <Column field="ttrest" body={(rowData, column) => {
                            return <div>
                                <span style={{ color: 'red', fontWeight: 'bold' }}>{this.gemsysService.formaterValueSep(parseFloat(rowData.ttrest))}</span>
                            </div>
                        }} header="Restant" style={{ width: '10%' }} sortable={true} />

                    </DataTable>
                </Dialog>

            </div>
        );
    }
}