import React, { Component } from 'react';
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { Dropdown } from 'primereact/dropdown';
import GestGemsysService from '../service/GestGemsysService';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';
import { InputMask } from 'primereact/inputmask';
import { TabView, TabPanel } from 'primereact/tabview';
import { InputNumber } from 'primereact/inputnumber';
import { FileUpload } from 'primereact/fileupload';
import { InputTextarea } from 'primereact/inputtextarea';
import { classNames } from 'primereact/utils';
import { MultiSelect } from 'primereact/multiselect';
import { SplitButton } from 'primereact/splitbutton';

export class FormInscription extends Component {
    constructor() {
        super();
        this.state = {
            rpvs: '',
            rpv: '',
            recole: '',
            liste_sessions: [
                { label: parseInt(moment(new Date()).format('YYYY')) - 3, value: parseInt(moment(new Date()).format('YYYY')) - 4 },
                { label: parseInt(moment(new Date()).format('YYYY')) - 3, value: parseInt(moment(new Date()).format('YYYY')) - 3 },
                { label: parseInt(moment(new Date()).format('YYYY')) - 2, value: parseInt(moment(new Date()).format('YYYY')) - 2 },
                { label: parseInt(moment(new Date()).format('YYYY')) - 1, value: parseInt(moment(new Date()).format('YYYY')) - 1 },
                { label: parseInt(moment(new Date()).format('YYYY')), value: parseInt(moment(new Date()).format('YYYY')) }
            ],
            rsession: parseInt(moment(new Date()).format('YYYY')),

            liste_service: [],
            liste_selectedService: [],
            liste_souscription: [],
            selectedSouscription: [],
            montantSouscription: 0,
            visible_dlg_souscription: false,
            modif_souscription: false,
            idService: null,

            formDataSouscription: {
                id: 0,
                dateSouscription: null,
                etatSouscription: "Actif",
                dateDesactivation: null,
                idInsc: null,
                idService: { id: 0 },
                montantSouscription: 0,
            },

            liste_oriente: [],
            selectedOriente: null,
            rtelTut: "",
            remailTut: "",
            rnomTut: "",

            idTut: '',
            nomTut: '',
            telTut: '',
            emailTut: '',
            fctTut: '',
            adrTut: '',

            idTutetud: '',
            typeRelation: '',
            idTut: '',
            matricule: '',
            dateAffectation: '',
            idInsc: '',
            typeInsc: 'Inscription',
            val: 0,
            aN: "A",
            dvalInsc: '',
            nivAnt: '',
            codeClasse: '',
            matricule: '',
            numStatut: '',
            optNotif: 'Non',
            bourse: 0,
            idEtabl: '',
            iduser: '',

            matricule: '',
            nomEleve: '',
            prenomEleve: '',
            dateNaiss: '',
            lieuNais: '',
            prenomPere: '',
            nomMere: '',
            sexe: 'Masculin',
            pref: '',
            langueMaternelle: '',
            situationMatrimoniale: 'Célibataire',
            telEleve: '',
            telPere: '',
            emailEleve: '',
            pvb: '',
            pvs: '',
            sessionb: '',
            centreb: '',
            ecole: '',
            profilb: '',
            dnorm: '',
            fonctParent: '',
            quartier: '',
            noinsc: '',
            denregEtud: '',
            numNation: '1407',
            groupeSanguin: 'X',
            photo: 'nophoto.png',

            liste_famille: [],
            liste_nations: null,
            liste_classes: null,
            liste_status: null,
            liste_tuteurs: [],
            liste_tuteurs_apart: [],
            num_ligne: 0,
            selectedTuteur: null,

            visibleTut: false,
            visibleOrientation: false,
            visible_dlg_form_famille: false,
            modifTut: false,
            file: null,

            activeIndex: 0,
            liste_versements_eleve: null,
            liste_eleves: [],
            selectedEleve: null,
            visibleInfos: false,
            modifInscrit: false,
            list_absences: [],
            typeInsc: 'Réinscription',
            ancienete: 'Ancien',
            rcodeClasse: null,
            rnomEleve: '',
            rmatricule: '',
            rsexe: null,
            rsituationMatrimoniale: null,
            liste_incription: [],
            selectedInsc: null,
            impactHandicap: 'Non',
            handicap: 'Non',
            typeHandicap: '',
            numQuitance: '',
            familleId: 0,
            formDataFamille: {
                id: 0,
                libelleFamille: "",
                contactFamille: "",
                adrFamille: ""
            },
            margeInsc: 0,
            remiseInsc: 0,
            visible_modif_eleve: false,
            modifEleve: false,
            visibleEleve: false,
            visibleInscrit: false,
            visibleTrf: false,
            submit: false,
            msg: '',
            codeClasseDest: null,
            liste_versements: [],
            liste_absence: [],
            file: null,
            showindicator: false,
            showindicatorFamille: false,
        };
        this.gemsysService = new GestGemsysService();
        this.conf_delete_inscrit = this.conf_delete_inscrit.bind(this);
        this.conf_delete_eleve = this.conf_delete_eleve.bind(this);
        this.edit_inscrit = this.edit_inscrit.bind(this);
        this.edit_souscription = this.edit_souscription.bind(this);
        this.edit_eleve = this.edit_eleve.bind(this);
        this.select_oriente = this.select_oriente.bind(this);
        this.upload_liste = this.upload_liste.bind(this);
    }

    static readUploadedFileAsText = inputFile => {
        const temporaryFileReader = new FileReader();
        return new Promise((resolve, reject) => {
            temporaryFileReader.onerror = () => {
                temporaryFileReader.abort();
                reject(new DOMException("Problem parsing input file."));
            };
            temporaryFileReader.onload = () => {
                resolve(temporaryFileReader.result);
            };
            temporaryFileReader.readAsText(inputFile);
        });
    };

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }

    upload_liste = async (e) => {
        try {
            const csv = await FormInscription.readUploadedFileAsText(e.files[0]);
            var lines = csv.split("\n");
            console.log(lines);
            var result = [];
            Array.prototype.insert = function (index) {
                this.splice.apply(this, [index, 0].concat(this.slice.call(arguments, 1)));
            };
            let headers = lines[0].split(";");
            if (headers.length < 17) {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Le fichier choisi ne respecte pas le format attendu' });
                return;
            }
            for (var i = 1; i < lines.length; i++) {
                var currentline = lines[i].split(";");
                if (currentline[0] == null || currentline[0] == '' || currentline[0] == undefined || currentline[9] == null || currentline[9] == '' || currentline[9] == undefined) {
                    break;
                }
                console.log(currentline[9]);
                var date = '';
                try {
                    date = moment(currentline[4], 'DD/MM/YYYY').format('DD/MM/YYYY');
                } catch (error) {
                    date = '';
                }
                if (date == 'Invalid date' || date == '') {
                    date = moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY');
                }
                var obj = {
                    ine: currentline[9],
                    nom: currentline[1],
                    prenom: currentline[2],
                    pv: currentline[8],
                    profil: currentline[11],
                    sessionExamen: currentline[10],
                    dateNaiss: date,
                    lieuNaiss: currentline[5],
                    sexe: currentline[3] == 'homme' ? 'M' : 'F',
                    pere: currentline[6],
                    mere: currentline[7],
                    centre: currentline[12],
                    ecoleOrigine: currentline[13],
                    programme: currentline[16],
                    matricule: currentline[0],
                    tel: currentline[14],
                    email: currentline[15],
                    idClasse: '0',
                };
                result.insert(i, obj);
            }
            if (result.length > 0) {
                this.gemsysService.AddOriente(result).then(data => {
                    this.resultat(data.code, data.contenu)
                }
                );
                this.forceUpdate();
            } else {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: "Aucune données enregistrée, merci de vérifier le contenu du fichier" });
            }
        } catch (e) {
            alert(e);
        }

    }


    componentDidMount() {
        this.gemsysService.getClasse().then(data => this.setState({ liste_classes: data }));
        this.gemsysService.getStatut().then(data => this.setState({ liste_status: data }, () => {
            this.get_default_statut();
        }));
        this.gemsysService.getNationnalite().then(data => this.setState({ liste_nations: data }));
        this.get_liste_famille();
        this.get_liste_prestation();
    }

    /* Récupération de la liste globale des prestations annexes */
    get_liste_prestation() {
        this.setState({ showindicator: true, liste_service: [] });
        this.gemsysService.get_liste_codif_prestation('TYPE_RECETTE', 'PRESTATIONS ANNEXES').then(data => {
            this.setState({ showindicator: false });
            if (data !== undefined && data !== null) {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.id, label: x.libelleCodif, tarif: x.valCodif }
                    });
                    this.setState({ liste_service: liste });
                }
            }
        });
    }

    /* Récupération de la liste des souscriptions par élève */
    get_liste_souscription() {
        this.setState({ showindicator: true });
        this.gemsysService.get_liste_souscription_matricule(this.state.selectedEleve.matricule).then(data => {
            this.setState({ liste_souscription: data, showindicator: false });
        });
    }


    transfere_eleve() {
        if (this.state.selectedInsc == null || this.state.selectedInsc == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à transférer' });
        } else if (this.state.codeClasseDest == null || this.state.codeClasseDest == '' || this.state.codeClasseDest == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la classe de destination' });
        } else if (this.state.codeClasseDest == this.state.selectedInsc.codeClasse.codeClasse) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'La classe de destination doit être différente de la classe actuelle' });
        } else {
            let trns = {
                idInscSrce: this.state.selectedInsc.idInsc,
                codeClasseDest: this.state.codeClasseDest
            }
            this.setState({ showindicator: true });
            this.gemsysService.TransfertInscrit(trns).then(data => this.resultatInscrit(data.code, data.contenu));
        }
    }

    transfere_eleve_conf(data) {
        this.setState({ selectedInsc: data });
        this.setState({ visibleTrf: true });
    }

    toogle_souscription(form, etat) {
        form.etatSouscription = etat;
        this.setState({ showindicator: true });
        this.gemsysService.save_souscription(form).then(reponse => {
            this.setState({ showindicator: false });
            if (reponse != undefined && reponse != null) {
                this.resultat(reponse.code, reponse.contenu);
                if (reponse.code == 200) {
                    this.get_liste_souscription();
                }
            }
        });
    }

    actionTemplateSouscription(rowData, column) {
        let items = [
            {
                label: 'Modifier',
                icon: 'pi pi-pencil',
                command: (e) => {
                    this.edit_souscription(rowData);
                }
            },
        ]
        if (rowData.etatSouscription == 'Actif') {
            items.push(
                {
                    label: 'Résilier',
                    icon: 'pi pi-ban',
                    command: (e) => {
                        this.toogle_souscription(rowData, 'Résilié');
                    }
                }
            )
        } else {
            items.push(
                {
                    label: 'Activer',
                    icon: 'pi pi-check',
                    command: (e) => {
                        this.toogle_souscription(rowData, 'Actif');
                    }
                }
            )
        }
        return <SplitButton label="Modifier" icon="pi pi-pencil" onClick={(e) => this.edit_souscription(rowData)} model={items}></SplitButton>
    }

    actionTemplateEleve(rowData, column) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button onClick={(e) => this.edit_eleve(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button disabled={this.gemsysService.get_userconnected().profilu !== 'Administrateur'} onClick={(e) => this.conf_delete_eleve(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }

    photoTemplate(rowData, column) {
        let fic = rowData.photo;
        if (fic === undefined || fic === null || fic === '') {
            fic = 'nophoto.png';
        }
        return <img src={this.gemsysService.download_file(fic, 'photos/')} alt='Photo' style={{ width: 45, height: 45 }} />
    }

    actionTemplate = (rowData) => {
        return (<div className="grid" style={{ width: 120 }}>
            <div className="col"><Button onClick={() => this.edit_inscrit(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button disabled={this.gemsysService.get_userconnected().profilu !== 'Administrateur'} onClick={() => this.conf_delete_inscrit(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div>
            <div className="col"><Button disabled={this.gemsysService.get_userconnected().profilu !== 'Administrateur'} onClick={() => this.transfere_eleve_conf(rowData)} tooltip='Transferer' className="p-button-warning" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-reply" /></div>
        </div>
        );
    }

    delete_inscrit() {
        if (this.state.selectedEleve == null || this.state.selectedEleve == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.gemsysService.DeleteInscrit(this.state.selectedInsc).then(data => this.resultatInscrit(data.code, data.contenu));
        }
    }

    delete_eleve() {
        if (this.state.selectedEleve == null || this.state.selectedEleve == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.gemsysService.DeleteEleve(this.state.selectedEleve).then(data => this.resultatInscrit(data.code, data.contenu));
            this.rechercher_eleve();
        }
    }

    conf_delete_eleve(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedEleve: data });
            this.setState({ msg: data.matricule + " " + data.nomEleve + " " + data.prenomEleve });
            this.setState({ visibleEleve: true });
        }
    }

    conf_delete_inscrit(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedEleve: data });
            this.setState({ msg: data.codeClasse.libClasse + " " + data.codeClasse.optClasse + " de " + data.matricule.prenomEleve + " " + data.matricule.nomEleve });
            this.setState({ visibleInscrit: true });
        }
    }

    show_orientation() {
        this.setState({ visibleOrientation: true });
    }

    resultatInscrit(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            if (this.state.selectedEleve !== null && this.state.selectedEleve !== undefined) {
                this.charger_infos_eleve();
            }
            this.annuler_eleve();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false, visibleTrf: false });
    }


    annuler_eleve() {
        this.setState({ matricule: '' });
        this.setState({ nomEleve: '' });
        this.setState({ prenomEleve: '' });
        this.setState({ dateNaiss: '' });
        this.setState({ lieuNais: '' });
        this.setState({ prenomPere: "" });
        this.setState({ nomMere: '' });
        this.setState({ sexe: 'M' });
        this.setState({ pref: '' });
        this.setState({ langueMaternelle: "" });
        this.setState({ situationMatrimoniale: 'Célibataire' });
        this.setState({ telEleve: '' });
        this.setState({ telPere: '' });
        this.setState({ emailEleve: '' });
        this.setState({ anneeUniv: "" });
        this.setState({ pvb: '' });
        this.setState({ pvs: '' });
        this.setState({ sessionb: '' });
        this.setState({ centreb: '' });
        this.setState({ ecole: '' });
        this.setState({ profilb: '' });
        this.setState({ dnorm: '' });
        this.setState({ fonctParent: '' });
        this.setState({ quartier: '' });
        this.setState({ noinsc: '' });
        // this.setState({ groupeSanguin: '' });
        this.setState({ photo: 'nophoto.png' });
        this.setState({ nomTut: '' });
        this.setState({ telTut: '' });
        this.setState({ fctTut: '' });
        this.setState({
            visible_modif_eleve: false,
            modifEleve: false,
            familleId: 0
        });
        this.forceUpdate();
        window.scrollTo(0, 0);
    }

    /* Remplissage du formulaire avec les informations de la ligne sélectionnée dans le cadre de la modification  */
    edit_souscription(data) {
        if (data !== null && data !== undefined) {
            this.setState({ formDataSouscription: data });
            this.setState({ modif_souscription: true });
            this.setState({ visible_dlg_souscription: true });
            this.setState({ selectedSouscription: data });
        }
    }

    edit_inscrit(data) {
        this.setState({ idInsc: data.idInsc });
        this.setState({ typeInsc: data.typeInsc });
        this.setState({ aN: data.aN });
        this.setState({ nivAnt: data.nivAnt });
        this.setState({ classe: data.codeClasse.codeClasse });
        this.setState({ numStatut: data.numStatut.numStatut });
        this.setState({ optNotif: data.optNotif });
        this.setState({ bourse: data.bourse });
        this.setState({ numQuitance: data.numQuitance });
        this.setState({ handicap: data.handicap });
        this.setState({ impactHandicap: data.impactHandicap });
        this.setState({ modifInscrit: true });
        this.setState({ selectedInsc: data });
        this.setState({
            margeInsc: data.margeInsc,
            remiseInsc: data.remiseInsc,
        })
    }

    edit_eleve(data) {
        if (data != null && data != undefined) {
            this.setState({ matricule: data.matricule });
            this.setState({ numNation: data.numNation.numNation });
            this.setState({ nomEleve: data.nomEleve });
            this.setState({ prenomEleve: data.prenomEleve });
            this.setState({ dateNaiss: data.dateNaiss });
            this.setState({ lieuNais: data.lieuNais });
            this.setState({ nomMere: data.nomMere });
            this.setState({ prenomPere: data.prenomPere });
            this.setState({ sexe: data.sexe });
            this.setState({ pref: data.pref });
            this.setState({ langueMaternelle: data.langueMaternelle });
            this.setState({ situationMatrimoniale: data.situationMatrimoniale });
            this.setState({ telEleve: data.telEleve });
            this.setState({ telPere: data.telPere });
            this.setState({ emailEleve: data.emailEleve });
            this.setState({ pvb: data.pvb });
            this.setState({ pvs: data.pvs });
            this.setState({ sessionb: data.sessionb });
            this.setState({ centreb: data.centreb });
            this.setState({ ecole: data.ecole });
            this.setState({ profilb: data.profilb });
            this.setState({ dnorm: data.dnorm });
            this.setState({ fonctParent: data.fonctParent });
            this.setState({ quartier: data.quartier });
            this.setState({ nomTut: data.nomTut });
            this.setState({ telTut: data.telTut });
            this.setState({ fctTut: data.fctTut });
            this.setState({ noinsc: data.noinsc });
            this.setState({ groupeSanguin: data.groupeSanguin });
            this.setState({ selectedEleve: data });
            this.setState({ visible_modif_eleve: true, modifEleve: true });
            this.setState({ photo: data.photo });
        }
    }

    annule_inscrit() {
        this.setState({ idInsc: '' });
        this.setState({ dateInsc: '' });
        this.setState({ typeInsc: 'Réinscription' });
        this.setState({ val: '' });
        this.setState({ aN: '' });
        this.setState({ anneeInsc: '' });
        this.setState({ ancienete: 'Ancien' });
        this.setState({ denregInsc: '' });
        this.setState({ dvalInsc: '' });
        this.setState({ nivAnt: '' });
        this.setState({ classe: null });
        this.setState({ matricule: '' });
        this.setState({ state: null });
        this.setState({ optNotif: 'Oui' });
        this.setState({ bourse: 0 });
        this.setState({ modifInscrit: false });
        this.setState({
            margeInsc: 0,
            remiseInsc: 0,
        })
        this.forceUpdate();
    }

    /* Annuler l'opération en cours et vider le formulaire */
    annuler_souscription() {
        this.setState({
            formDataSouscription: {
                id: 0,
                dateSouscription: null,
                etatSouscription: "Actif",
                dateDesactivation: null,
                idInsc: null,
                idService: { id: 0 },
                montantSouscription: 0,
                
            },
        });
        this.setState({ showindicator: false });
        this.setState({ modif_souscription: false });
    }

    enreg_inscrit() {
        if (this.state.typeInsc == "" || this.state.numStatut == "" || this.state.optNotif == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            if (this.state.modifInscrit == false) {
                if (this.state.codeClasse == "" || this.state.codeClasse == undefined) {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la classe' });
                    return;
                }
                if (this.state.selectedEleve == "" || this.state.selectedEleve == undefined || this.state.selectedEleve == null) {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: "Veuillez sélectionner l'élève" });
                    return;
                }
                let insc = {
                    idInsc: '0',
                    typeInsc: this.state.typeInsc,
                    val: 0,
                    aN: this.state.typeInsc == 'Inscription' ? 'N' : 'A',
                    anneeInsc: this.gemsysService.get_rannee(),
                    nivAnt: this.state.nivAnt,
                    codeClasse: { codeClasse: this.state.codeClasse },
                    matricule: this.state.selectedEleve,
                    numStatut: { numStatut: this.state.numStatut },
                    optNotif: this.state.optNotif,
                    bourse: this.state.bourse,
                    numQuitance: this.state.numQuitance,
                    handicap: this.state.handicap,
                    typeHandicap: this.state.typeHandicap,
                    impactHandicap: this.state.impactHandicap,
                    remiseInsc: this.state.remiseInsc,
                    margeInsc: this.state.margeInsc,
                };
                this.setState({ showindicator: true });
                this.gemsysService.AddInscrit(insc).then(data => this.resultatInscrit(data.code, data.contenu));
            } else {
                let insc = this.state.selectedInsc;
                insc.typeInsc = this.state.typeInsc;
                insc.aN = this.state.typeInsc == 'Inscription' ? 'N' : 'A';
                insc.nivAnt = this.state.nivAnt;
                insc.numStatut = { numStatut: this.state.numStatut };
                insc.optNotif = this.state.optNotif;
                insc.bourse = this.state.bourse;
                insc.numQuitance = this.state.numQuitance;
                insc.handicap = this.state.handicap;
                insc.typeHandicap = this.state.typeHandicap;
                insc.impactHandicap = this.state.impactHandicap;
                insc.remiseInsc= this.state.remiseInsc;
                insc.margeInsc= this.state.margeInsc;
                this.setState({ showindicator: true });
                this.gemsysService.UpdateInscrit(insc).then(data => this.resultatInscrit(data.code, data.contenu));
            }
        }
    }

    /* Enregistrer les informations du formulaire famille */
    enreg_famille() {
        this.setState({ submit: true });
        if (this.state.formDataFamille.libelleFamille == "" || this.state.formDataFamille.adrFamille == "" || this.state.formDataFamille.contactFamille == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
        } else {
            this.setState({ showindicatorFamille: true });
            let form = this.state.formDataFamille;
            this.gemsysService.save_famille(form).then(reponse => {
                this.setState({ showindicatorFamille: false });
                if (reponse != undefined && reponse != null) {
                    this.resultat(reponse.code, reponse.contenu);
                    this.setState({ visible_dlg_form_famille: false });
                    if (reponse.code == 200) {
                        this.get_liste_famille();
                    }
                }
            });
        }
    }

    enreg_data_souscription() {
        this.setState({ submit: true });
        if (this.state.formDataSouscription.idService.id == 0 || this.state.formDataSouscription.montantSouscription == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
        } else {
            let form = this.state.formDataSouscription;
            this.setState({ showindicator: true });
            if (this.state.modif_souscription == false) {
                let lastInsc = this.state.liste_incription[this.state.liste_incription.length - 1];
                form.idInsc = { idInsc: lastInsc.idInsc };
            }
            this.gemsysService.save_souscription(form).then(reponse => {
                this.setState({ showindicator: false });
                if (reponse != undefined && reponse != null) {
                    this.resultat(reponse.code, reponse.contenu);
                    if (reponse.code == 200) {
                        this.charger_infos_eleve();
                        if (this.state.modif_souscription == true) {
                            this.setState({ visible_dlg_souscription: false });
                        }
                        this.annuler_souscription();
                    }
                }
            });
        }
    }

    enreg_eleve() {
        if (this.state.nomEleve == "" || this.state.prenomEleve == "" || this.state.dateNaiss == "" || this.state.lieuNais == "" || this.state.prenomPere == ""
            || this.state.nomMere == "" || this.state.sexe == "" || this.state.situationMatrimoniale == ""
            || this.state.telEleve == "" || this.state.numStatut == null || this.state.groupeSanguin == "" || this.state.numNation == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else if (moment(this.state.dateNaiss, 'DD/MM/YYYY').format('DD/MM/YYYY') == 'Invalid date') {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez vérifier la date de naissance' });
        } else {
            let upload = false;
            let nomfic = this.state.photo;
            if (this.state.file != null && this.state.file != undefined) {
                let name = this.state.file.name;
                nomfic = this.gemsysService.get_userconnected().numUtil + moment(new Date()).format('DDMMYYYYHHmmss') + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
                upload = true;
            }
            if (this.state.modifEleve == false) {
                let liste_sousc = this.state.liste_selectedService.length > 0 ?
                    this.state.liste_selectedService.map(x => {
                        return {
                            id: 0,
                            iduser: this.gemsysService.get_userconnected().numUtil,
                            etatSouscription: "Actif",
                            idInsc: { idInsc: 0 },
                            idService: { id: x },
                            montantSouscription: this.gemsysService.getSelectedData(this.state.liste_service, x).tarif,
                        }
                    }) : [];
                let eleve = {
                    matricule: this.state.matricule,
                    nomEleve: this.state.nomEleve,
                    prenomEleve: this.state.prenomEleve,
                    dateNaiss: moment(this.state.dateNaiss, 'DD/MM/YYYY').format('DD/MM/YYYY'),
                    lieuNais: this.state.lieuNais,
                    prenomPere: this.state.prenomPere,
                    nomMere: this.state.nomMere,
                    sexe: this.state.sexe,
                    pref: this.state.pref,
                    langueMaternelle: this.state.langueMaternelle,
                    situationMatrimoniale: this.state.situationMatrimoniale,
                    telEleve: this.state.telEleve,
                    telPere: this.state.telPere,
                    emailEleve: this.state.emailEleve,
                    anneeUniv: this.gemsysService.get_rannee(),
                    pvb: this.state.pvb,
                    pvs: this.state.pvs,
                    sessionb: this.state.sessionb,
                    centreb: this.state.centreb,
                    ecole: this.state.ecole,
                    profilb: this.state.profilb,
                    dnorm: this.state.dnorm,
                    fonctParent: this.state.fonctParent,
                    quartier: this.state.quartier,
                    noinsc: this.state.noinsc,
                    numNation: { numNation: this.state.numNation },
                    groupeSanguin: this.state.groupeSanguin,
                    nomTut: this.state.nomTut,
                    telTut: this.state.telTut,
                    fctTut: this.state.fctTut,
                    photo: nomfic,
                    familleId: { id: this.state.familleId }
                };
                let inscrit = {
                    idInsc: '0',
                    typeInsc: this.state.typeInsc,
                    val: 0,
                    aN: 'N',
                    anneeInsc: this.gemsysService.get_rannee(),
                    nivAnt: this.state.nivAnt,
                    codeClasse: { codeClasse: this.state.codeClasse },
                    matricule: eleve,
                    numStatut: { numStatut: this.state.numStatut },
                    optNotif: this.state.optNotif,
                    bourse: this.state.bourse,
                    numQuitance: this.state.numQuitance,
                    handicap: this.state.handicap,
                    typeHandicap: this.state.typeHandicap,
                    impactHandicap: this.state.impactHandicap,
                    typeInsc: 'Inscription',
                    listeSouscription: liste_sousc
                };
                this.setState({ showindicator: true });
                this.gemsysService.AddInscription(inscrit).then(data => {
                    if (data != undefined && data != null) {
                        if (upload === true) {
                            this.fileUpload(this.state.file, nomfic);
                        }
                        this.resultatInscrit(data.code, data.contenu);
                    }
                });
            } else {
                let eleve = this.state.selectedEleve;
                eleve.matricule = this.state.matricule;
                eleve.nomEleve = this.state.nomEleve;
                eleve.prenomEleve = this.state.prenomEleve;
                eleve.dateNaiss = moment(this.state.dateNaiss, 'DD/MM/YYYY').format('DD/MM/YYYY');
                eleve.lieuNais = this.state.lieuNais;
                eleve.prenomPere = this.state.prenomPere;
                eleve.nomMere = this.state.nomMere;
                eleve.sexe = this.state.sexe;
                eleve.pref = this.state.pref;
                eleve.langueMaternelle = this.state.langueMaternelle;
                eleve.situationMatrimoniale = this.state.situationMatrimoniale;
                eleve.telEleve = this.state.telEleve;
                eleve.telPere = this.state.telPere;
                eleve.emailEleve = this.state.emailEleve;
                eleve.pvb = this.state.pvb;
                eleve.pvs = this.state.pvs;
                eleve.sessionb = this.state.sessionb;
                eleve.centreb = this.state.centreb;
                eleve.ecole = this.state.ecole;
                eleve.profilb = this.state.profilb;
                eleve.dnorm = this.state.dnorm;
                eleve.fonctParent = this.state.fonctParent;
                eleve.quartier = this.state.quartier;
                eleve.noinsc = this.state.noinsc;
                eleve.numNation = { numNation: this.state.numNation };
                eleve.groupeSanguin = this.state.groupeSanguin;
                eleve.nomTut = this.state.nomTut;
                eleve.telTut = this.state.telTut;
                eleve.fctTut = this.state.fctTut;
                eleve.photo = nomfic;
                this.setState({ showindicator: true });
                this.gemsysService.UpdateEleve(eleve).then(data => {
                    if (data != undefined && data != null) {
                        if (upload === true) {
                            this.fileUpload(this.state.file, nomfic);
                        }
                        this.resultatInscrit(data.code, data.contenu);
                    }
                });
            }
        }
    }

    fileUpload(file, namefile) {
        this.gemsysService.upload_file(file, namefile, 'photos/').then(data => {
            if (data != null && data != undefined && data.code == '200') {
                this.toast.show({ severity: 'success', summary: 'Success', detail: 'Chargement effectué avec succès' });
            } else {
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Echec upload' });
            }
        });
    }

    rechercher_oriente() {
        this.setState({ showindicator: true });
        let rpv = 'Tous';
        let rpvs = 'Tous';
        let recole = 'Tous';
        if (this.state.rpv !== undefined && this.state.rpv !== null && this.state.rpv !== '') {
            rpv = this.state.rpv;
        }
        if (this.state.rpvs !== undefined && this.state.rpvs !== null && this.state.rpvs !== '') {
            rpvs = this.state.rpvs;
        }
        if (this.state.recole !== undefined && this.state.recole !== null && this.state.recole !== '') {
            recole = this.state.recole;
        }
        this.setState({ showindicator: true });
        this.gemsysService.getliste_oriente(rpvs, rpv, recole, this.state.rsession).then(data => {
            this.setState({ showindicator: false, liste_oriente: data });
        });
    }

    charger_infos_eleve() {
        let data = this.state.selectedEleve;
        this.setState({ showindicator: true });
        if (data !== null && data !== undefined) {
            if (this.state.activeIndex == 0) {
                this.gemsysService.getliste_inscrit_by_eleve(data.matricule).then(data => this.setState({ liste_incription: data, showindicator: false }));
            } else if (this.state.activeIndex == 1) {
                this.gemsysService.get_liste_versements_by_matricule_eleve(data.matricule).then(data => this.setState({ liste_versements: data, showindicator: false }));
            } else if (this.state.activeIndex == 2) {
                this.gemsysService.getliste_inscrit_by_eleve(data.matricule).then(data => this.setState({ liste_incription: data, showindicator: false }));
                this.setState({ showindicator: true });
                this.gemsysService.get_liste_souscription_matricule(data.matricule).then(data => this.setState({ liste_souscription: data, showindicator: false }));
            } else {
                this.gemsysService.get_liste_absence_by_matricule_eleve(data.matricule).then(data => this.setState({ list_absences: data, showindicator: false }));
            }
        }
    }

    /* Récupération de la liste globale des familles */
    get_liste_famille() {
        this.setState({ showindicatorFamille: true, liste_famille: [] });
        this.gemsysService.get_liste_famille().then(data => {
            this.setState({ showindicatorFamille: false });
            if (data !== undefined && data !== null) {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.id, label: x.libelleFamille }
                    });
                    this.setState({ liste_famille: liste });
                }
            }
        });
    }

    get_default_statut() {
        let liste = [];
        if (this.gemsysService.get_categorie_etabl() == 'Privé') {
            if (this.state.liste_status.length > 0) {
                liste = this.state.liste_status.filter(function (el) {
                    return el.statutFinancier === 'Formation payante' && el.statutAccademique === 'Formation en cours';
                });
            }
        } else {
            liste = this.state.liste_status.filter(function (el) {
                return el.statutFinancier === 'Boursier' && el.statutAccademique === 'Formation en cours';
            });
        }
        if (liste.length > 0) {
            this.setState({ numStatut: liste[0].numStatut });
        }
    }

    select_oriente(data) {
        if (data != null && data != undefined) {
            this.setState({
                matricule: data.matricule,
                nomEleve: data.nom,
                prenomEleve: data.prenom,
                ecole: data.ecoleOrigine,
                lieuNais: data.lieuNaiss,
                nomMere: data.mere,
                prenomPere: data.pere,
                telEleve: data.tel,
                emailEleve: data.email,
                sexe: data.sexe,
                dateNaiss: data.dateNaiss,
                profilb: data.profil,
                centreb: data.centre,
                sessionb: data.sessionExamen,
                pvb: data.pv,
                pvs: data.ine,
                pref: data.pref,
                codeClasse: data.idClasse,
                nomTut: data.pere,
                telTut: data.tel,
                fctTut: 'COMMERÇANT',
                numQuitance: 'Q' + data.matricule,
                nivAnt: this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ? 'Terminal' : '',
                visibleOrientation: false
            });

        }
    }

    actionTemplateOriente(rowData) {
        return <Button onClick={() => this.select_oriente(rowData)} className="p-button-info" style={{ width: 120 }} icon="pi pi-check-circle" label="Sélectionner" />
    }


    openFileUpload() {
        document.getElementById("hiddenFile").click();
    }


    rechercher_eleve() {
        let rcodeClasse = 'Tous';
        let rsexe = 'Tous';
        let rnomEleve = 'Tous';
        let rmatricule = 'Tous';
        let rsituationMatrimoniale = 'Tous';

        if (this.state.rcodeClasse !== null && this.state.rcodeClasse !== undefined && this.state.rcodeClasse !== "Tous" && this.state.rcodeClasse !== "") {
            rcodeClasse = this.state.rcodeClasse;
        }

        if (this.state.rnomEleve !== '' && this.state.rnomEleve !== null) {
            rnomEleve = this.state.rnomEleve;
        }
        if (this.state.rmatricule !== '' && this.state.rmatricule !== null) {
            rmatricule = this.state.rmatricule;
        }
        if (this.state.rsexe !== '' && this.state.rsexe !== '' && this.state.rsexe !== 'Tous' && this.state.rsexe !== null) {
            rsexe = this.state.rsexe;
        }
        if (this.state.rsituationMatrimoniale !== '' && this.state.rsituationMatrimoniale !== 'Tous' && this.state.rsituationMatrimoniale !== null) {
            rsituationMatrimoniale = this.state.rsituationMatrimoniale;
        }
        this.setState({ showindicator: true });
        this.gemsysService.get_liste_eleve(rcodeClasse, rnomEleve, rmatricule, rsexe, rsituationMatrimoniale).then(data => this.setState({ liste_eleves: data, showindicator: false }));
    }

    render() {
        let liste_nations = null;
        let mode = 'liste';
        if (this.props.match.params.typeOp != null && this.props.match.params.typeOp != undefined) {
            mode = this.props.match.params.typeOp;
        }
        if (this.state.liste_nations != null && this.state.liste_nations != undefined) {
            liste_nations = this.state.liste_nations.map(x => {
                return { value: x.numNation, label: x.libNation }
            });
        }
        let liste_classes = null;
        let rliste_classes = [{ codeClasse: 'Tous', label: 'Classe' }];
        if (this.state.liste_classes != null && this.state.liste_classes != undefined) {
            liste_classes = this.state.liste_classes.map(x => {
                let lc = this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ? 'S' : '';
                return { value: x.codeClasse, label: lc + x.libClasse + " " + x.optClasse }
            });
            rliste_classes = rliste_classes.concat(liste_classes)
        }
        let genres = [
            { label: 'Masculin', value: 'M' },
            { label: 'Feminin', value: 'F' }
        ];

        let situations = [
            { label: 'Célibataire', value: 'Célibataire' },
            { label: 'Marié(e)', value: 'Marié(e)' },
            { label: 'Divorcé(e)', value: 'Divorcé(e)' },
            { label: 'Veuve', value: 'Veuve' }
        ];
        let liste_groupes = [
            { label: 'X', value: 'X' },
            { label: 'A', value: 'A' },
            { label: 'B', value: 'B' },
            { label: 'AB', value: 'AB' },
            { label: 'AB+', value: 'AB+' },
            { label: 'AB-', value: 'AB-' },
            { label: 'B+', value: 'B+' },
            { label: 'A+', value: 'A+' },
            { label: 'B-', value: 'B-' },
            { label: 'O+', value: 'O+' },
            { label: 'O-', value: 'O-' }
        ];

        let liste_status = [];
        if (this.state.liste_status != null && this.state.liste_status != undefined) {
            liste_status = this.state.liste_status.map(x => {
                return { value: x.numStatut, label: x.statutFinancier + "-" + x.statutAccademique, statutFinancier: x.statutFinancier }
            });
            if (this.gemsysService.get_userconnected().profilu !== 'Administrateur') {
                liste_status = liste_status.filter(function (el) {
                    return el.statutFinancier == 'Formation payante';
                });
            }
        }

        let notifications = [
            { label: 'Oui', value: 'Oui' },
            { label: 'Non', value: 'Non' }
        ];

        let liste_option = [
            { label: 'SM', value: 'SM' },
            { label: 'SE', value: 'SE' },
            { label: 'SS', value: 'SS' },
            { label: 'SM FA', value: 'SM FA' },
            { label: 'SE FA', value: 'SE FA' },
            { label: 'SS FA', value: 'SS FA' },
            { label: 'EG', value: 'EG' },
        ];

        let types = [
            { label: 'Réinscription', value: 'Réinscription' },
            { label: 'Inscription', value: 'Inscription' }
        ];

        let liste_niveau = [
            { value: 'Terminal', label: 'Terminal' },
            { value: 'Licence 1', label: 'Licence 1' },
            { value: 'Licence 2', label: 'Licence 2' },
            { value: 'Licence 3', label: 'Licence 3' },
            { value: 'Master 1', label: 'Master 1' },
            { value: 'Doctorat 1', label: 'Doctorat 1' }
        ];

        if (this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Professionnel') {
            liste_niveau = [
                { label: "7ème année", value: "7ème année" },
                { label: "8ème année", value: "8ème année" },
                { label: "9ème année", value: "9ème année" },
                { label: "10ème année", value: "10ème année" },
                { label: "11ème année", value: "11ème année" },
                { label: "12ème année", value: "12ème année" },
                { label: "Terminale", value: "Terminale" },
                { value: '1ère année', label: '1ère année' },
                { value: '2ème année', label: '2ème année' },
                { value: '3ème année', label: '3ème année' },
            ];
        } else if (this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Pré-universitaire') {
            liste_niveau = [
                { label: "Petite section", value: "Petite section" },
                { label: "Grande section", value: "Grande section" },
                { label: "Maternelle", value: "Maternelle" },
                { label: "CP1", value: "CP1" },
                { label: "CP2", value: "CP2" },
                { label: "CE1", value: "CE1" },
                { label: "CE2", value: "CE2" },
                { label: "CM1", value: "CM1" },
                { label: "CM2", value: "CM2" },
                { label: "7ème année", value: "7ème année" },
                { label: "8ème année", value: "8ème année" },
                { label: "9ème année", value: "9ème année" },
                { label: "10ème année", value: "10ème année" },
                { label: "11ème année", value: "11ème année" },
                { label: "12ème année", value: "12ème année" },
                { label: "Terminale", value: "Terminale" },
            ];
        }

        const dialogFooterEnregEleve = (
            <div>
                <Button icon="pi pi-times" onClick={() => this.annuler_eleve()} label="Annuler" className="p-button-secondary" />
                <Button label="Enregistrer" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_eleve()} />
            </div>
        );

        const dialogFooterDelInscrit = (
            <div>
                <Button icon="pi pi-times" onClick={() => this.setState({ visibleInscrit: false })} label="Annuler" className="p-button-secondary" />
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visibleInscrit: false });
                    this.delete_inscrit();
                }} label="Oui" loading={this.state.showindicator} />
            </div>
        );
        const dialogFooterDelEleve = (
            <div>
                <Button icon="pi pi-times" onClick={() => this.setState({ visibleEleve: false })} label="Annuler" className="p-button-secondary" />
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visibleEleve: false });
                    this.delete_eleve();
                }} label="Oui" loading={this.state.showindicator} />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Liste des orientés" visible={this.state.visibleOrientation} style={{ width: '85%', height: '400pxx' }} modal={true} onHide={(e) => this.setState({ visibleOrientation: false })}>
                    <div className="grid">
                        <div className="col-12">
                            <i className="fa fa-search"></i>
                            <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>

                            <div className="grid">
                                <div className="col-12 md:col-2">
                                    <InputText value={this.state.rpvs} placeholder="INE" onChange={(e) => this.setState({ rpvs: e.target.value })} style={{ width: '100%' }} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <InputText value={this.state.rpv} placeholder="PV" onChange={(e) => this.setState({ rpv: e.target.value })} style={{ width: '100%' }} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <InputText value={this.state.recole} placeholder="Ecole d'origine" onChange={(e) => this.setState({ recole: e.target.value })} style={{ width: '100%' }} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <Dropdown options={this.state.liste_sessions} value={this.state.rsession} onChange={(e) => this.setState({ rsession: e.value })} style={{ width: '100%' }} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <Button icon='pi pi-search' loading={this.state.showindicator} label='Rechercher' onClick={() => this.rechercher_oriente()} />
                                </div>
                            </div>

                            <DataTable value={this.state.liste_oriente} style={{ minHeight: '400px' }}
                                selectionMode="single" paginator={true} rows={8} paginatorPosition="top"
                                paginatorLeft={<FileUpload mode="basic" name="demo[]" auto accept=".csv" chooseLabel="Importer" className="p-mr-2 p-d-inline-block" onUpload={this.upload_liste} />}
                                onRowClick={e => this.setState({ selectedOriente: e.data })}
                                selection={this.state.selectedOriente}
                                dataKey="ine" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll">
                                <Column header="Action" body={this.actionTemplateOriente.bind(this)} style={{ width: '12%' }} />
                                <Column field="nom" header="Nom" style={{ width: '12%' }} filter={true} filterMatchMode='contains' />
                                <Column field="prenom" header="Prénoms" style={{ width: '15%' }} filter={true} filterMatchMode='contains' />
                                <Column field="dateNaiss" header="Naissance" style={{ width: '8%' }} filter={true} filterMatchMode='contains' />
                                <Column field="pv" header="PV" style={{ width: '7%' }} filter={true} filterMatchMode='contains' />
                                <Column field="profil" header="Profil" style={{ width: '6%' }} filter={true} filterMatchMode='contains' />
                                <Column field="programme" header="Programme" style={{ width: '22%' }} filter={true} filterMatchMode='contains' />
                            </DataTable>
                        </div>
                    </div>
                </Dialog>
                <Dialog header="Modification" visible={this.state.visible_modif_eleve} style={{ width: '90%' }} modal={true} onHide={(e) => this.setState({ visible_modif_eleve: false })} footer={dialogFooterEnregEleve}>
                    <div className="col-12">
                        <div className="grid">
                            <div className="col-12 md:col-3">
                                <label htmlFor="numNation">Nationalité *</label>
                            </div>
                            <div className="col-12 md:col-3">
                                <Dropdown options={liste_nations} optionLabel='label' filter filterBy='label' value={this.state.numNation} onChange={(e) => this.setState({ numNation: e.value })} autoWidth={false} />
                            </div>
                            <div className="col-6 md:col-3">
                                <label htmlFor="matricule">Matricule </label>
                            </div>
                            <div className="col-6 md:col-3">
                                <InputText disabled={true} id="matricule" value={this.state.matricule} placeholder="Matricule" onChange={(e) => this.setState({ matricule: e.target.value })} />
                            </div>
                            <div className="col-6 md:col-3">
                                <label htmlFor="nomEleve">Nom * </label>
                            </div>
                            <div className="col-6 md:col-3">
                                <InputText id="nomEleve" value={this.state.nomEleve} onChange={(e) => this.setState({ nomEleve: e.target.value })} />
                            </div>
                            <div className="col-6 md:col-3">
                                <label htmlFor="prenomEleve">Prénom *</label>
                            </div>
                            <div className="col-6 md:col-3">
                                <InputText id="prenomEleve" value={this.state.prenomEleve} onChange={(e) => this.setState({ prenomEleve: e.target.value })} />
                            </div>
                            <div className="col-6 md:col-3">
                                <label htmlFor="dateNaiss">Date de naissance *</label>
                            </div>
                            <div className="col-6 md:col-3">
                                <InputMask mask="99/99/9999" value={this.state.dateNaiss} onChange={e => this.setState({ dateNaiss: e.target.value })} />
                            </div>
                            <div className="col-6 md:col-3">
                                <label htmlFor="lieuNais">Lieu naissance *</label>
                            </div>
                            <div className="col-6 md:col-3">
                                <InputText id="lieuNais" value={this.state.lieuNais} onChange={(e) => this.setState({ lieuNais: e.target.value })} />
                            </div>
                            <div className="col-6 md:col-3">
                                <label htmlFor="prenomPere">Prénom père *</label>
                            </div>
                            <div className="col-6 md:col-3">
                                <InputText id="prenomPere" value={this.state.prenomPere} onChange={(e) => this.setState({ prenomPere: e.target.value })} />
                            </div>
                            <div className="col-6 md:col-3">
                                <label htmlFor="nomMere">Nom mère *</label>
                            </div>
                            <div className="col-6 md:col-3">
                                <InputText id="nomMere" value={this.state.nomMere} onChange={(e) => this.setState({ nomMere: e.target.value })} />
                            </div>
                            <div className="col-12 md:col-3">
                                <label htmlFor="sexe">Sexe *</label>
                            </div>
                            <div className="col-12 md:col-3">
                                <Dropdown options={genres} value={this.state.sexe} onChange={(e) => this.setState({ sexe: e.value })} autoWidth={false} />
                            </div>

                            <div className="col-12 md:col-3">
                                <label htmlFor="situationMatrimoniale">Situation maritale *</label>
                            </div>
                            <div className="col-12 md:col-3">
                                <Dropdown options={situations} value={this.state.situationMatrimoniale} onChange={(e) => this.setState({ situationMatrimoniale: e.value })} autoWidth={false} />
                            </div>
                            <div className="col-6 md:col-3">
                                <label htmlFor="telEleve">Téléphone *</label>
                            </div>
                            <div className="col-6 md:col-3">
                                <InputMask mask="999999999" id="telEleve" value={this.state.telEleve} onChange={(e) => this.setState({ telEleve: e.target.value })} />
                            </div>
                            {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ?
                                <div className="col-6 md:col-3">
                                    <label htmlFor="pvb">PV BAC </label>
                                </div> : null}
                            {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ?
                                <div className="col-6 md:col-3">
                                    <InputText id="pvb" value={this.state.pvb} onChange={(e) => this.setState({ pvb: e.target.value })} />
                                </div> : null}
                            {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ?
                                <div className="col-6 md:col-3">
                                    <label htmlFor="centreb">Centre BAC </label>
                                </div> : null}
                            {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ?
                                <div className="col-6 md:col-3">
                                    <InputText id="centreb" value={this.state.centreb} onChange={(e) => this.setState({ centreb: e.target.value })} />
                                </div> : null}
                            {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ?
                                <div className="col-6 md:col-3">
                                    <label htmlFor="sessionb">Session BAC </label>
                                </div> : null}
                            {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ?
                                <div className="col-6 md:col-3">
                                    <InputText id="sessionb" value={this.state.sessionb} onChange={(e) => this.setState({ sessionb: e.target.value })} />
                                </div> : null}
                            {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ?
                                <div className="col-6 md:col-3">
                                    <label htmlFor="profilb">Profil BAC </label>
                                </div> : null}
                            {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ?
                                <div className="col-6 md:col-3">
                                    <Dropdown options={liste_option} value={this.state.profilb} onChange={(e) => this.setState({ profilb: e.value })} autoWidth={false} />
                                </div> : null}
                            {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ?
                                <div className="col-6 md:col-3">
                                    <label htmlFor="pvs">INE </label>
                                </div> : null}
                            {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ?
                                <div className="col-6 md:col-3">
                                    <InputText id="pvs" value={this.state.pvs} onChange={(e) => this.setState({ pvs: e.target.value })} />
                                </div> : null}
                            {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ?
                                <div className="col-6 md:col-3">
                                    <label htmlFor="ecole">Ecole d'origine </label>
                                </div> : null}
                            {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ?
                                <div className="col-6 md:col-3">
                                    <InputText id="ecole" value={this.state.ecole} onChange={(e) => this.setState({ ecole: e.target.value })} />
                                </div> : null}

                            <div className="col-6 md:col-3">
                                <label htmlFor="quartier">Quartier </label>
                            </div>
                            <div className="col-6 md:col-3">
                                <InputText id="quartier" value={this.state.quartier} onChange={(e) => this.setState({ quartier: e.target.value })} />
                            </div>
                            <div className="col-12 md:col-3">
                                <label htmlFor="groupeSanguin">Groupe sanguin </label>
                            </div>
                            <div className="col-12 md:col-3">
                                <Dropdown options={liste_groupes} value={this.state.groupeSanguin} onChange={(e) => this.setState({ groupeSanguin: e.value })} autoWidth={false} />
                            </div>

                            <div className="col-6 md:col-3">
                                <label htmlFor="emailEleve">Email</label>
                            </div>
                            <div className="col-6 md:col-3">
                                <InputText id="emailEleve" value={this.state.emailEleve} onChange={(e) => this.setState({ emailEleve: e.target.value })} />
                            </div>

                            <div className="col-6 md:col-3">
                                <label >Nom tuteur *</label>
                            </div>
                            <div className="col-6 md:col-3">
                                <InputText value={this.state.nomTut} onChange={(e) => this.setState({ nomTut: e.target.value })} />
                            </div>
                            <div className="col-6 md:col-3">
                                <label >Téléphone tuteur *</label>
                            </div>
                            <div className="col-6 md:col-3">
                                <InputMask mask="999999999" value={this.state.telTut} onChange={(e) => this.setState({ telTut: e.target.value })} />
                            </div>
                            <div className="col-6 md:col-3">
                                <label >Occupation tuteur *</label>
                            </div>
                            <div className="col-6 md:col-3">
                                <InputText value={this.state.fctTut} onChange={(e) => this.setState({ fctTut: e.target.value })} />
                            </div>

                            <div className="col-12 md:col-3">
                                <label htmlFor="img">Photo</label>
                            </div>
                            <div className="col-12 md:col-3" >
                                <a onClick={() => this.openFileUpload()} style={{ cursor: 'pointer' }}>
                                    <div style={{ height: 90, width: 85, borderRadius: 10 }} >
                                        <center>
                                            {this.state.urlfile == null ?
                                                <div style={{ width: '85px', height: '90px', borderRadius: 10, backgroundColor: '#f3f7fb', display: 'flex', justifyContent: 'center', padding: 30 }} ><label style={{ color: '#003366', fontWeight: 'bold', cursor: 'pointer' }}>
                                                    <i style={{ fontSize: 30, color: '#000' }} className="pi pi-camera"></i>
                                                </label></div> :
                                                <img src={this.state.urlfile} style={{ width: '85px', height: '90px', borderRadius: 10, backgroundColor: '#f3f7fb', border: '1px solid #CCC' }} />}
                                        </center>
                                    </div>
                                </a>
                                <input type="file" id="hiddenFile" style={{ visibility: 'hidden' }} onChange={(e) => {
                                    if (e.target.files && e.target.files[0]) {
                                        this.setState({ file: e.target.files[0] }, () => {
                                            let url = URL.createObjectURL(this.state.file);
                                            this.setState({ urlfile: url });
                                        })
                                    }
                                }} />
                                {/* <input type="file" onChange={(e) => this.setState({ file: e.target.files[0] })} /> */}
                            </div>
                        </div>
                    </div>
                </Dialog>
                <Dialog header="Confirmation" visible={this.state.visibleInscrit} style={{ width: '250px' }} modal={true} footer={dialogFooterDelInscrit} onHide={() => this.setState({ visibleInscrit: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <Dialog header="Confirmation" visible={this.state.visibleEleve} style={{ width: '250px' }} modal={true} footer={dialogFooterDelEleve} onHide={() => this.setState({ visibleEleve: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <Dialog header="Confirmation transfert" visible={this.state.visibleTrf} style={{ width: '450px' }} modal={true} onHide={() => this.setState({ visibleTrf: false })}>
                    <div>
                        <div className="card" style={{ backgroundColor: '#CFF3FC', marginBottom: -5 }} >
                            NOM: <b>{this.state.selectedInsc !== null && this.state.selectedInsc !== undefined ? this.state.selectedInsc.matricule.prenomEleve + ' ' + this.state.selectedInsc.matricule.nomEleve : ''} </b>
                        </div>
                        <div className="col-12 md:col-12">
                            <label htmlFor="classe">Classe destination *</label><br />
                            <Dropdown options={liste_classes} optionLabel='label' panelStyle={{ width: '450px' }} filter value={this.state.codeClasseDest} onChange={(e) => this.setState({ codeClasseDest: e.target.value })} autoWidth={false} appendTo={document.body} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <center>
                        <div className="grid" >
                            <div className="col"><Button style={{ width: 100 }} label="Annuler" icon="pi pi-times" onClick={() => this.setState({ visibleTrf: false })} className="p-button-secondary" /></div>
                            <div className="col"><Button style={{ width: 100 }} label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.transfere_eleve()} /></div>
                        </div>
                    </center>
                </Dialog>

                <Dialog header="Souscription" visible={this.state.visible_dlg_souscription} style={{ width: '450px' }} modal={true} onHide={() => this.setState({ visible_dlg_souscription: false })}
                    footer={<>
                        <Button label="Fermer" icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => {
                            this.annuler_souscription();
                            this.setState({ visible_dlg_souscription: false })
                        }} />
                        <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_data_souscription()} style={{ width: 120 }} />
                    </>}>
                    <div className="p-fluid formgrid grid">

                        <div className="field col-12 md:col-3">
                            <label htmlFor="email1"> Prestation *</label>
                        </div>
                        <div className="field col-12 md:col-9">
                            <Dropdown autoWidth={false} value={this.state.formDataSouscription.idService.id} filter={true} filterBy='label' filterMatchMode='contains'
                                options={this.state.liste_service}
                                onChange={(e) => this.setState({ formDataSouscription: this.gemsysService.onInputChange(e, 'idService', 'fk', this.state.formDataSouscription) }, () => {
                                    let form = this.state.formDataSouscription;
                                    let montant = this.gemsysService.getSelectedData(this.state.liste_service, this.state.formDataSouscription.idService.id).tarif;
                                    form.montantSouscription = montant;
                                    this.setState({ formDataSouscription: form });
                                })}
                                appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && this.state.formDataSouscription.idService.id == 0 })} />
                            {this.state.submit && !this.state.formDataSouscription.idService.id && <small className="p-invalid" style={{ color: 'red' }}>La prestation est obligatoire.</small>}
                        </div>

                        <div className="field col-12 md:col-3">
                            <label htmlFor="email1"> Tarif *</label>
                        </div>
                        <div className="field col-12 md:col-9">
                            <InputNumber id="name" value={this.state.formDataSouscription.montantSouscription} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataSouscription.montantSouscription })}
                                onChange={(e) => this.setState({ formDataSouscription: this.gemsysService.onInputChange(e, 'montantSouscription', '', this.state.formDataSouscription) })}
                                required min={0} locale="en-US" minFractionDigits={0} />
                            {this.state.submit && !this.state.formDataSouscription.montantSouscription && <small className="p-invalid" style={{ color: 'red' }}>Le tarif est obligatoire.</small>}
                        </div>
                    </div>
                </Dialog>

                {/* Boite de dialogue d'ajout et de modification d'une famille  */}
                <Dialog visible={this.state.visible_dlg_form_famille} style={{ width: '450px' }} header="Nouvelle famille" modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form_famille: false })}
                    footer={<>
                        <Button label="Fermer" icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => {
                            this.setState({
                                formData: {
                                    id: 0,
                                    libelleFamille: "",
                                    contactFamille: "",
                                    adrFamille: ""
                                },
                                visible_dlg_form_famille: false
                            })
                        }} />
                        <Button label="Valider" loading={this.state.showindicatorFamille} icon="pi pi-check" onClick={(e) => this.enreg_famille()} style={{ width: 120 }} />
                    </>}>
                    <div className="p-fluid">

                        <div className="field">
                            <label htmlFor="name1">Famille *</label>
                            <InputText id="name1" value={this.state.formDataFamille.libelleFamille} className={classNames({ 'p-invalid': this.state.submit && !this.state.formDataFamille.libelleFamille })}
                                onChange={(e) => this.setState({ formDataFamille: this.gemsysService.onInputChange(e, 'libelleFamille', '', this.state.formDataFamille) })}
                                required autoFocus />
                            {this.state.submit && !this.state.formDataFamille.libelleFamille && <small className="p-invalid" style={{ color: 'red' }}>Le libelle est obligatoire.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="name1">Contact *</label>
                            <InputText id="name1" value={this.state.formDataFamille.contactFamille} onChange={(e) => this.setState({ formDataFamille: this.gemsysService.onInputChange(e, 'contactFamille', '', this.state.formDataFamille) })} />
                        </div>
                        <div className="field">
                            <label htmlFor="email1">Adresse *  </label>
                            <InputTextarea id="name" value={this.state.formDataFamille.adrFamille} rows={2} onChange={(e) => this.setState({ formDataFamille: this.gemsysService.onInputChange(e, 'adrFamille', '', this.state.formDataFamille) })} />
                        </div>
                    </div>
                </Dialog>


                {mode == 'liste' ?
                    <div className="grid">
                        <div className="col-12">
                            <div className="card card-w-title">
                                <div className="grid">
                                    <div className="col-12 md:col-2">
                                        <Dropdown options={rliste_classes} optionLabel='label' panelStyle={{ width: '400px' }} filter={true} filterMatchMode='contains' placeholder="Classe" value={this.state.rcodeClasse} onChange={(e) => this.setState({ rcodeClasse: e.value }, () => {
                                            this.rechercher_eleve();
                                        })} autoWidth={false} />
                                    </div>
                                    <div className="col-12 md:col-2">
                                        <InputText id="rnomEleve" value={this.state.rnomEleve} placeholder="Nom prénoms" onChange={(e) => this.setState({ rnomEleve: e.target.value })} />
                                    </div>
                                    <div className="col-12 md:col-2">
                                        <InputText id="rmatricule" value={this.state.rmatricule} placeholder="Matricule" onChange={(e) => this.setState({ rmatricule: e.target.value })} />
                                    </div>
                                    <div className="col-12 md:col-2">
                                        <Dropdown options={[{ value: 'Tous', label: 'Sexe' }].concat(genres)} value={this.state.rsexe} placeholder="Sexe" onChange={(e) => this.setState({ rsexe: e.value }, () => {
                                            this.rechercher_eleve();
                                        })} autoWidth={false} />
                                    </div>
                                    <div className="col-12 md:col-2">
                                        <Dropdown options={[{ value: 'Tous', label: 'Situation maritale' }].concat(situations)} value={this.state.rsituationMatrimoniale} placeholder="Situation maritale" onChange={(e) => this.setState({ rsituationMatrimoniale: e.value }, () => {
                                            this.rechercher_eleve();
                                        })} autoWidth={false} />
                                    </div>
                                    <div className="col-12 md:col-2">
                                        <Button label="Rechercher" loading={this.state.showindicator} icon="pi pi-search" onClick={() => this.rechercher_eleve()} />
                                    </div>
                                </div>
                                <DataTable value={this.state.liste_eleves} style={{ minHeight: '370px' }}
                                    selectionMode="single" paginator rows={7}
                                    onRowClick={e => this.setState({ selectedEleve: e.data }, () => {
                                        this.charger_infos_eleve();
                                    })}
                                    selection={this.state.selectedEleve} dataKey="matricule" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll">
                                    <Column body={this.actionTemplateEleve.bind(this)} header="Actions" style={{ width: '7%' }} />
                                    <Column body={this.photoTemplate.bind(this)} header="Photo" style={{ width: '5%' }} />
                                    <Column field="matricule" header="Matricule" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                    <Column field="nomEleve" body={(data) => {
                                        return <div>
                                            {data.nomEleve}
                                        </div>
                                    }} header="Nom" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                    <Column field="prenomEleve" body={(data) => {
                                        return <div>
                                            {data.prenomEleve}
                                        </div>
                                    }} header="Prénoms" style={{ width: '20%' }} filter={true} filterMatchMode='contains' />
                                    <Column field="groupeSanguin" header="GRS" style={{ width: '5%' }} filter={true} filterMatchMode='contains' />
                                    <Column field="sexe" header="Sexe" style={{ width: '5%' }} filter={true} filterMatchMode='contains' />
                                    <Column field="telEleve" header="Téléphone" style={{ width: '8%' }} filter={true} filterMatchMode='contains' />
                                    <Column field="dateNaiss" body={(data) => {
                                        return <div>
                                            {data.dateNaiss}
                                        </div>
                                    }} header="Naissance" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                </DataTable>

                                <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => {
                                    this.setState({ activeIndex: e.index }, () => {
                                        this.charger_infos_eleve();
                                    });
                                }}>
                                    <TabPanel header="Classes">
                                        <DataTable value={this.state.liste_incription} responsive={true} emptyMessage="Aucune donnée trouvée" selectionMode="single"
                                            onRowClick={(e) => this.setState({ selectedInsc: e.data })} style={{ minHeight: "200px" }}
                                            onRowSelect={(e) => this.setState({ selectedInsc: e.data })} paginator rows={6} alwaysShowPaginator={false}
                                            selection={this.state.selectedInsc} dataKey="idInsc" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll">
                                            <Column body={this.actionTemplate.bind(this)} header="Actions" style={{ width: '10%' }} />
                                            <Column field="anneeInsc" header="Année" style={{ width: '8%' }} />
                                            <Column field="codeClasse.libClasse" body={(data) => {
                                                return <div>
                                                    {data.codeClasse.libClasse + " " + data.codeClasse.optClasse}
                                                </div>
                                            }} header="Classe" style={{ width: '20%' }} />
                                            <Column field="val" header="Etat" body={(data) => {
                                                let etat = 'En attente';
                                                if (data.val == 1) {
                                                    etat = 'Validé';
                                                } else if (data.val == 2) {
                                                    etat = 'Annulé';
                                                }
                                                return <div>
                                                    {etat}
                                                </div>
                                            }} style={{ width: '8%' }} />
                                            <Column field="numStatut.statutAccademique" body={(data) => {
                                                return <div>
                                                    {data.numStatut.statutAccademique + " " + data.numStatut.statutFinancier}
                                                </div>
                                            }} header="Statut" style={{ width: '18%' }} />
                                            <Column field="dateInsc" header="Date" style={{ width: '8%' }} />
                                            <Column field="typeInsc" header="Type" style={{ width: '8%' }} />
                                        </DataTable>
                                        <div className="p-fluid p-formgrid grid">
                                            {this.state.modifInscrit == false ?
                                                <div className="p-field col-12 md:col-4">
                                                    <label htmlFor="classe">Classe *</label>
                                                    <Dropdown options={liste_classes} optionLabel='label' panelStyle={{ width: '400px' }} filter value={this.state.codeClasse} onChange={(e) => this.setState({ codeClasse: e.target.value })} autoWidth={false} />
                                                </div>
                                                : null}

                                            <div className="p-field col-12 md:col-4">
                                                <label htmlFor="typeInsc">Type *</label>
                                                <Dropdown options={types} value={this.state.typeInsc} onChange={(e) => this.setState({ typeInsc: e.target.value })} autoWidth={false} />
                                            </div>

                                            {this.gemsysService.get_userconnected().profilu == 'Administrateur' ?
                                                <div className="p-field col-12 md:col-4">
                                                    <label htmlFor="status">Statut *</label>
                                                    <Dropdown options={liste_status} optionLabel='label' filter value={this.state.numStatut} onChange={(e) => this.setState({ numStatut: e.target.value })} autoWidth={false} />
                                                </div>
                                                : null}

                                            <div className="p-field col-12 md:col-4">
                                                <label htmlFor="optNotif">Notification *</label>
                                                <Dropdown options={notifications} value={this.state.optNotif} onChange={(e) => this.setState({ optNotif: e.target.value })} autoWidth={false} />
                                            </div>

                                            <div className="p-field col-12 md:col-4">
                                                <label htmlFor="salaire">Remise </label>
                                                <InputNumber value={this.state.remiseInsc} onChange={(e) => this.setState({ remiseInsc: e.value })} />
                                            </div>

                                            <div className="p-field col-12 md:col-4">
                                                <label htmlFor="salaire">Marge </label>
                                                <InputNumber value={this.state.margeInsc} onChange={(e) => this.setState({ margeInsc: e.value })} />
                                            </div>

                                            {this.gemsysService.get_userconnected().idEtabl.typeEtabl !== 'Pré-universitaire' ?
                                                <>
                                                    <div className="p-field col-12 md:col-4">
                                                        <label htmlFor="salaire">Bourse </label>
                                                        <InputNumber value={this.state.bourse} onChange={(e) => this.setState({ bourse: e.value })} />
                                                    </div>

                                                    <div className="p-field col-12 md:col-4">
                                                        <label htmlFor="adrCli">Niveau antérieur *</label>
                                                        <Dropdown value={this.state.nivAnt} options={liste_niveau} optionLabel='label' onChange={(e) => this.setState({ nivAnt: e.value })} autoWidth={false} style={{ width: '95%' }} />
                                                    </div>

                                                    <div className="p-field col-12 md:col-4">
                                                        <label htmlFor="salaire">N° quitance </label>
                                                        <InputText value={this.state.numQuitance} onChange={(e) => this.setState({ numQuitance: e.target.value })} style={{ width: '95%' }} />
                                                    </div>

                                                    <div className="p-field col-12 md:col-4">
                                                        <label htmlFor="adrCli">Etes-vous handicap ? </label>
                                                        <Dropdown value={this.state.handicap} options={
                                                            [
                                                                { value: 'Oui', label: 'Oui' },
                                                                { value: 'Non', label: 'Non' },
                                                            ]} optionLabel='label' onChange={(e) => this.setState({ handicap: e.value })} autoWidth={false} style={{ width: '95%' }} />
                                                    </div>

                                                    <div className="p-field col-12 md:col-4">
                                                        <label >Si oui, quel type d'handicap ? </label>
                                                        <InputText value={this.state.typeHandicap} onChange={(e) => this.setState({ typeHandicap: e.target.value })} style={{ width: '95%' }} />
                                                    </div>

                                                    <div className="p-field col-12 md:col-4">
                                                        <label htmlFor="adrCli">Etes-vous affecté par cet handicap dans vos études ?</label>
                                                        <Dropdown value={this.state.impactHandicap} options={
                                                            [
                                                                { value: 'Oui', label: 'Oui' },
                                                                { value: 'Non', label: 'Non' },
                                                            ]} optionLabel='label' onChange={(e) => this.setState({ impactHandicap: e.value })} autoWidth={false} style={{ width: '95%' }} />
                                                    </div>
                                                </>
                                                : null}
                                        </div>
                                        <center>
                                            <div className="grid" style={{ width: 300 }}>
                                                <div className="col"><Button label="Annuler" icon="pi pi-ban" onClick={() => this.annule_inscrit()} /></div>
                                                <div className="col"><Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={() => this.enreg_inscrit()} /></div>
                                            </div>
                                        </center>
                                    </TabPanel>

                                    <TabPanel header="Versement">
                                        <div className="grid">
                                            <div className="col-12">
                                                <DataTable value={this.state.liste_versements} paginator rows={10}
                                                    style={{ minHeight: '300px' }} responsive={true} csvSeparator=';'
                                                    dataKey="idVers" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll">
                                                    <Column field="dateVers" header="Date" style={{ width: '8%' }} />
                                                    <Column field="libVers" header="Libellé " style={{ width: '20%' }} />
                                                    <Column field="classeVers" body={(rowData) => {
                                                        return <div>
                                                            <span>{rowData.classeVers}</span>
                                                        </div>
                                                    }} header="Classe" style={{ width: '30%' }} />
                                                    <Column field="cmtVers" header="Commentaire" style={{ width: '30%' }} />
                                                    <Column field="montVers" body={(rowData, column) => {
                                                        return <div>
                                                            <span style={{ color: 'green' }}>{this.gemsysService.formaterValueSep(parseFloat(rowData.montVers))}</span>
                                                        </div>
                                                    }} header="Montant" style={{ width: '12%' }} />
                                                </DataTable>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel header="Prestations">
                                        <div className="grid">
                                            <div className="col-12">
                                                <Button label="Ajouter" loading={this.state.showindicator} icon="pi pi-plus" onClick={(e) => this.setState({ modif_souscription: false, visible_dlg_souscription: true })} style={{ width: 120 }} />

                                                <DataTable value={this.state.liste_souscription} paginator rows={10} style={{ minHeight: '300px' }}
                                                    resizableColumns="scroll" showGridlines className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                                    selection={this.state.selectedSouscription} onSelectionChange={e => this.setState({ selectedSouscription: e.value })}
                                                    emptyMessage="No data found" alwaysShowPaginator={false} selectionMode="single" >
                                                    <Column body={this.actionTemplateSouscription.bind(this)} header="Action" style={{ width: '10%' }} />
                                                    <Column field="dateSouscription" header="Date" style={{ width: '10%' }} />
                                                    <Column field="idService.libelleCodif" header="Prestation" />
                                                    <Column field="montantSouscription" body={(rowData, column) => {
                                                        return <div>
                                                            <span>{this.gemsysService.formaterValueSep(parseFloat(rowData.montantSouscription))}</span>
                                                        </div>
                                                    }} header="Tarif" style={{ width: '10%' }} />
                                                    <Column field="etatSouscription" body={(rowData, column) => {
                                                        let color = 'black';
                                                        if (rowData.etatSouscription == 'Résilié') {
                                                            color = 'red';
                                                        }
                                                        return <div>
                                                            <span style={{ fontWeight: 'bold', color: color }}>{rowData.etatSouscription}</span><br />
                                                            <span>{rowData.dateDesactivation}</span>
                                                        </div>
                                                    }} header="Etat" style={{ width: '12%' }} />
                                                </DataTable>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel header="Absences">
                                        <div className="grid">
                                            <div className="col-12">
                                                <DataTable value={this.state.liste_absence} paginator rows={10}
                                                    style={{ minHeight: '300px' }} dataKey="idAbs" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" >
                                                    <Column field="dateDebutAbs" header="Début" style={{ width: '13%' }} />
                                                    <Column field="dateFinAbs" header="Fin" style={{ width: '12%' }} />
                                                    <Column field="idInsc.codeClasse.libClasse" body={(rowData) => {
                                                        return <div>
                                                            <span> {rowData.idInsc.codeClasse.libClasse + " " + rowData.idInsc.codeClasse.optClasse} </span>
                                                        </div>
                                                    }} header="Etudiant" style={{ width: '20%' }} />
                                                    <Column field="motifAbs" header="Motif" style={{ width: '20%' }} />
                                                    <Column field="typeAbs" header="Type" style={{ width: '15%' }} />
                                                </DataTable>
                                            </div>
                                        </div>
                                    </TabPanel>
                                </TabView>



                            </div>
                        </div>
                    </div>
                    :
                    <div className="grid">
                        <div className="col-12">
                            <div className="card card-w-title">
                                <i className="pi pi-user"></i> Infomrations {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ? <Button label="Orientation" style={{ width: 120, float: 'right' }} icon="pi pi-search" onClick={() => this.show_orientation()} className="p-button-warning" /> : null}
                                <hr />
                                <Panel>
                                    <div className="grid">
                                        <div className="col-12 md:col-3">
                                            <label htmlFor="codeClasse">Classe *</label>
                                        </div>
                                        <div className="col-12 md:col-3">
                                            <Dropdown options={liste_classes} optionLabel='label' filter filterBy='label' value={this.state.codeClasse} onChange={(e) => this.setState({ codeClasse: e.value })} autoWidth={false} panelStyle={{ width: '400px' }} />
                                        </div>
                                        <div className="col-12 md:col-3">
                                            <label htmlFor="numStatut">Statut *</label>
                                        </div>
                                        <div className="col-12 md:col-3">
                                            <Dropdown options={liste_status} optionLabel='label' filter filterBy='label' value={this.state.numStatut} onChange={(e) => this.setState({ numStatut: e.value })} autoWidth={false} />
                                        </div>
                                        <div className="col-12 md:col-3">
                                            <label htmlFor="numNation">Nationalité *</label>
                                        </div>
                                        <div className="col-12 md:col-3">
                                            <Dropdown options={liste_nations} optionLabel='label' filter filterBy='label' value={this.state.numNation} onChange={(e) => this.setState({ numNation: e.value })} autoWidth={false} />
                                        </div>
                                        <div className="col-6 md:col-3">
                                            <label htmlFor="matricule">Matricule </label>
                                        </div>
                                        <div className="col-6 md:col-3">
                                            <InputText id="matricule" value={this.state.matricule} placeholder="Matricule" onChange={(e) => this.setState({ matricule: e.target.value })} />
                                        </div>
                                        <div className="col-6 md:col-3">
                                            <label htmlFor="nomEleve">Nom * </label>
                                        </div>
                                        <div className="col-6 md:col-3">
                                            <InputText id="nomEleve" value={this.state.nomEleve} onChange={(e) => this.setState({ nomEleve: e.target.value })} />
                                        </div>
                                        <div className="col-6 md:col-3">
                                            <label htmlFor="prenomEleve">Prénom *</label>
                                        </div>
                                        <div className="col-6 md:col-3">
                                            <InputText id="prenomEleve" value={this.state.prenomEleve} onChange={(e) => this.setState({ prenomEleve: e.target.value })} />
                                        </div>
                                        <div className="col-6 md:col-3">
                                            <label htmlFor="dateNaiss">Date de naissance *</label>
                                        </div>
                                        <div className="col-6 md:col-3">
                                            <InputMask mask="99/99/9999" value={this.state.dateNaiss} onChange={e => this.setState({ dateNaiss: e.target.value })} />
                                        </div>
                                        <div className="col-6 md:col-3">
                                            <label htmlFor="lieuNais">Lieu naissance *</label>
                                        </div>
                                        <div className="col-6 md:col-3">
                                            <InputText id="lieuNais" value={this.state.lieuNais} onChange={(e) => this.setState({ lieuNais: e.target.value })} />
                                        </div>
                                        <div className="col-6 md:col-3">
                                            <label htmlFor="prenomPere">Prénom père *</label>
                                        </div>
                                        <div className="col-6 md:col-3">
                                            <InputText id="prenomPere" value={this.state.prenomPere} onChange={(e) => this.setState({ prenomPere: e.target.value })} />
                                        </div>
                                        <div className="col-6 md:col-3">
                                            <label htmlFor="nomMere">Nom mère *</label>
                                        </div>
                                        <div className="col-6 md:col-3">
                                            <InputText id="nomMere" value={this.state.nomMere} onChange={(e) => this.setState({ nomMere: e.target.value })} />
                                        </div>
                                        <div className="col-12 md:col-3">
                                            <label htmlFor="sexe">Sexe *</label>
                                        </div>
                                        <div className="col-12 md:col-3">
                                            <Dropdown options={genres} value={this.state.sexe} onChange={(e) => this.setState({ sexe: e.value })} autoWidth={false} />
                                        </div>

                                        <div className="col-12 md:col-3">
                                            <label htmlFor="situationMatrimoniale">Situation maritale *</label>
                                        </div>
                                        <div className="col-12 md:col-3">
                                            <Dropdown options={situations} value={this.state.situationMatrimoniale} onChange={(e) => this.setState({ situationMatrimoniale: e.value })} autoWidth={false} />
                                        </div>
                                        <div className="col-6 md:col-3">
                                            <label htmlFor="telEleve">Téléphone *</label>
                                        </div>
                                        <div className="col-6 md:col-3">
                                            <InputMask mask="999999999" id="telEleve" value={this.state.telEleve} onChange={(e) => this.setState({ telEleve: e.target.value })} />
                                        </div>
                                        {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ?
                                            <div className="col-6 md:col-3">
                                                <label htmlFor="pvb">PV BAC </label>
                                            </div> : null}
                                        {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ?
                                            <div className="col-6 md:col-3">
                                                <InputText id="pvb" value={this.state.pvb} onChange={(e) => this.setState({ pvb: e.target.value })} />
                                            </div> : null}
                                        {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ?
                                            <div className="col-6 md:col-3">
                                                <label htmlFor="centreb">Centre BAC </label>
                                            </div> : null}
                                        {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ?
                                            <div className="col-6 md:col-3">
                                                <InputText id="centreb" value={this.state.centreb} onChange={(e) => this.setState({ centreb: e.target.value })} />
                                            </div> : null}
                                        {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ?
                                            <div className="col-6 md:col-3">
                                                <label htmlFor="sessionb">Session BAC </label>
                                            </div> : null}
                                        {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ?
                                            <div className="col-6 md:col-3">
                                                <InputText id="sessionb" value={this.state.sessionb} onChange={(e) => this.setState({ sessionb: e.target.value })} />
                                            </div> : null}
                                        {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ?
                                            <div className="col-6 md:col-3">
                                                <label htmlFor="profilb">Profil BAC </label>
                                            </div> : null}
                                        {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ?
                                            <div className="col-6 md:col-3">
                                                <Dropdown options={liste_option} value={this.state.profilb} onChange={(e) => this.setState({ profilb: e.value })} autoWidth={false} />
                                            </div> : null}
                                        {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ?
                                            <div className="col-6 md:col-3">
                                                <label htmlFor="pvs">INE </label>
                                            </div> : null}
                                        {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ?
                                            <div className="col-6 md:col-3">
                                                <InputText id="pvs" value={this.state.pvs} onChange={(e) => this.setState({ pvs: e.target.value })} />
                                            </div> : null}
                                        {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ?
                                            <div className="col-6 md:col-3">
                                                <label htmlFor="ecole">Ecole d'origine </label>
                                            </div> : null}
                                        {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ?
                                            <div className="col-6 md:col-3">
                                                <InputText id="ecole" value={this.state.ecole} onChange={(e) => this.setState({ ecole: e.target.value })} />
                                            </div> : null}
                                        <div className="col-6 md:col-3">
                                            <label htmlFor="noinsc">Dossiers manquants  </label>
                                        </div>
                                        <div className="col-6 md:col-3">
                                            <InputText id="noinsc" value={this.state.noinsc} onChange={(e) => this.setState({ noinsc: e.target.value })} />
                                        </div>
                                        <div className="col-6 md:col-3">
                                            <label htmlFor="quartier">Quartier </label>
                                        </div>
                                        <div className="col-6 md:col-3">
                                            <InputText id="quartier" value={this.state.quartier} onChange={(e) => this.setState({ quartier: e.target.value })} />
                                        </div>
                                        <div className="col-12 md:col-3">
                                            <label htmlFor="groupeSanguin">Groupe sanguin </label>
                                        </div>
                                        <div className="col-12 md:col-3">
                                            <Dropdown options={liste_groupes} value={this.state.groupeSanguin} onChange={(e) => this.setState({ groupeSanguin: e.value })} autoWidth={false} />
                                        </div>

                                        <div className="col-6 md:col-3">
                                            <label htmlFor="emailEleve">Email</label>
                                        </div>
                                        <div className="col-6 md:col-3">
                                            <InputText id="emailEleve" value={this.state.emailEleve} onChange={(e) => this.setState({ emailEleve: e.target.value })} />
                                        </div>
                                        <div className="col-12 md:col-3">
                                            <label htmlFor="optNotif">Notification </label>
                                        </div>
                                        <div className="col-12 md:col-3">
                                            <Dropdown options={notifications} value={this.state.optNotif} onChange={(e) => this.setState({ optNotif: e.value })} autoWidth={false} />
                                        </div>
                                        <div className="col-6 md:col-3">
                                            <label >Nom tuteur *</label>
                                        </div>
                                        <div className="col-6 md:col-3">
                                            <InputText value={this.state.nomTut} onChange={(e) => this.setState({ nomTut: e.target.value })} />
                                        </div>
                                        <div className="col-6 md:col-3">
                                            <label >Téléphone tuteur *</label>
                                        </div>
                                        <div className="col-6 md:col-3">
                                            <InputMask mask="999999999" value={this.state.telTut} onChange={(e) => this.setState({ telTut: e.target.value })} />
                                        </div>
                                        <div className="col-6 md:col-3">
                                            <label >Occupation tuteur *</label>
                                        </div>
                                        <div className="col-6 md:col-3">
                                            <InputText value={this.state.fctTut} onChange={(e) => this.setState({ fctTut: e.target.value })} />
                                        </div>
                                        <div className="col-6 md:col-3">
                                            <label htmlFor="adrCli">Niveau antérieur *</label>
                                        </div>
                                        <div className="col-6 md:col-3">
                                            <Dropdown value={this.state.nivAnt} options={liste_niveau} optionLabel='label' onChange={(e) => this.setState({ nivAnt: e.value })} autoWidth={false} style={{ width: '95%' }} />
                                        </div>
                                        {this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ?
                                            <>
                                                <div className="col-6 md:col-3">
                                                    <label htmlFor="adrCli">Etes-vous handicap ? </label>
                                                </div>
                                                <div className="col-6 md:col-3">
                                                    <Dropdown value={this.state.handicap} options={
                                                        [
                                                            { value: 'Oui', label: 'Oui' },
                                                            { value: 'Non', label: 'Non' },
                                                        ]} optionLabel='label' onChange={(e) => this.setState({ handicap: e.value })} autoWidth={false} style={{ width: '95%' }} />
                                                </div>
                                                <div className="col-6 md:col-3">
                                                    <label >Si oui, quel type d'handicap ? </label>
                                                </div>
                                                <div className="col-6 md:col-3">
                                                    <InputText value={this.state.typeHandicap} onChange={(e) => this.setState({ typeHandicap: e.target.value })} style={{ width: '95%' }} />
                                                </div>
                                                <div className="col-6 md:col-3">
                                                    <label htmlFor="adrCli">Etes-vous affecté(e)s par cet handicap dans vos études ? </label>
                                                </div>
                                                <div className="col-6 md:col-3">
                                                    <Dropdown value={this.state.impactHandicap} options={
                                                        [
                                                            { value: 'Oui', label: 'Oui' },
                                                            { value: 'Non', label: 'Non' },
                                                        ]} optionLabel='label' onChange={(e) => this.setState({ impactHandicap: e.value })} autoWidth={false} style={{ width: '95%' }} />
                                                </div>
                                                <div className="col-12 md:col-3">
                                                    <label htmlFor="salaire">N° quitance </label>
                                                </div>
                                                <div className="col-12 md:col-3" >
                                                    <InputText value={this.state.numQuitance} onChange={(e) => this.setState({ numQuitance: e.target.value })} style={{ width: '95%' }} />
                                                </div>
                                                <div className="col-6 md:col-3">
                                                    <label >Bourse</label>
                                                </div>
                                                <div className="col-6 md:col-3">
                                                    <InputNumber value={this.state.bourse} onChange={(e) => this.setState({ bourse: e.value })} />
                                                </div>
                                            </>
                                            : null}
                                        <div className="col-12 md:col-3">
                                            <label htmlFor="img">Photo</label>
                                        </div>
                                        <div className="col-12 md:col-3" >
                                            <a onClick={() => this.openFileUpload()} style={{ cursor: 'pointer' }}>
                                                <div style={{ height: 90, width: 85, borderRadius: 10 }} >
                                                    <center>
                                                        {this.state.urlfile == null ?
                                                            <div style={{ width: '85px', height: '90px', borderRadius: 10, backgroundColor: '#f3f7fb', display: 'flex', justifyContent: 'center', padding: 30 }} ><label style={{ color: '#003366', fontWeight: 'bold', cursor: 'pointer' }}>
                                                                <i style={{ fontSize: 30, color: '#000' }} className="pi pi-camera"></i>
                                                            </label></div> :
                                                            <img src={this.state.urlfile} style={{ width: '85px', height: '90px', borderRadius: 10, backgroundColor: '#f3f7fb', border: '1px solid #CCC' }} />}
                                                    </center>
                                                </div>
                                            </a>
                                            <input type="file" id="hiddenFile" style={{ visibility: 'hidden' }} onChange={(e) => {
                                                if (e.target.files && e.target.files[0]) {
                                                    this.setState({ file: e.target.files[0] }, () => {
                                                        let url = URL.createObjectURL(this.state.file);
                                                        this.setState({ urlfile: url });
                                                    })
                                                }
                                            }} />
                                            {/* <input type="file" onChange={(e) => this.setState({ file: e.target.files[0] })} /> */}
                                        </div>

                                        <div className="col-12 md:col-6">
                                            <div className='grid'>
                                                <div className="col-12 md:col-6">
                                                    <label htmlFor="famille">Famille </label>
                                                </div>
                                                <div className="col-12 md:col-6">
                                                    <div className="p-inputgroup">
                                                        <Dropdown options={this.state.liste_famille} value={this.state.familleId} onChange={(e) => this.setState({ familleId: e.value })} autoWidth={false} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' panelStyle={{ width: '400px' }} />
                                                        <Button icon='pi pi-plus' style={{ width: 35 }} onClick={() => {
                                                            this.setState({ visible_dlg_form_famille: true });
                                                        }} loading={this.state.showindicatorFamille} />
                                                    </div>
                                                </div>
                                                <div className="col-12 md:col-6">
                                                    <label htmlFor="famille">Prestations annexes </label>
                                                </div>
                                                <div className="col-12 md:col-6">
                                                    <MultiSelect value={this.state.liste_selectedService} display="chip"
                                                        options={this.state.liste_service} onChange={(e) => this.setState({ liste_selectedService: e.value }, () => {
                                                        })} optionLabel="label" />
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </Panel>
                                <center>
                                    <div className="grid" style={{ width: 150, marginTop: '0%' }}>
                                        <div className="col"><Button loading={this.state.showindicator} label="Enregistrer" icon="pi pi-check" onClick={(e) => this.enreg_eleve()} /></div>
                                    </div>
                                </center>
                            </div>
                        </div>
                    </div>
                }





            </div>
        );
    }
}